<template>
  <b-modal
    size="xmd"
    v-model="activeModal"
    title="Add Solution"
    ok-title="Save"
    cancel-title="Cancel"
    :no-close-on-backdrop="true"
    :ok-disabled="loading"
    :cancel-disabled="loading"
    :hide-header-close="loading"
    @hidden="closeMe"
    content-class="modal-details"
    scrollable
  >
    <b-overlay
      id="overlay-background"
      :show="loading"
      :variant="skin"
      :opacity="0.5"
      blur="2px"
      rounded="sm"
    >
      <ValidationObserver
        ref="formObservableSolution"
        v-slot="{ passes }"
        lazy
        v-bind:vid="'form-solution'"
      >
        <b-form ref="formSolution" @submit.stop.prevent="passes(SaveSolution)">
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col cols="4">
                  <span class="title-tag">CLIENT NAME</span>
                  <div class="border border-primary rounded">
                    <p
                      class="text-primary d-flex align-items-center justify-content-center my-1s"
                      style="gap: 5px"
                    >
                      <!-- <feather-icon
                        class="ml-1"
                        :icon="
                          selectedClaim.cliente_account_status === 2
                            ? 'TriangleIcon'
                            : 'CircleIcon'
                        "
                        size="9"
                        :fill="colorStatus"
                        :style="{ color: colorStatus }"
                      /> -->
                      <status-account
                        :account="selectedClaim"
                        :text="false"
                      ></status-account>
                      {{ selectedClaim.client }}
                    </p>
                  </div>
                </b-col>
                <b-col cols="4">
                  <span class="title-tag">ACCOUNT</span>
                  <div class="border border-primary rounded">
                    <div
                      class="text-primary d-flex align-items-center justify-content-center my-1s"
                    >
                      {{ selectedClaim.account }}
                    </div>
                  </div>
                </b-col>
                <b-col cols="4">
                  <span class="title-tag">PROGRAM</span>
                  <div class="border border-primary rounded">
                    <p
                      class="text-primary d-flex align-items-center justify-content-center my-1s"
                    >
                      {{ selectedClaim.program }}
                    </p>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="6">
                  <b-row>
                    <itemValue :text="selectedClaim.type_claim" title="Type" />
                    <itemValue
                      :text="selectedClaim.source_claim"
                      title="Source"
                    />
                    <itemValue
                      :text="`$ ${selectedClaim.amount_department}`"
                      v-if="selectedClaim.amount_department"
                      title="Amount suggered by department"
                    />
                    <itemValue
                      :text="`$ ${selectedClaim.amount_client}`"
                      v-if="selectedClaim.amount_client"
                      title="Amount requested by client"
                    />
                  </b-row>
                  <b-row>
                    <itemValue
                      :text="selectedClaim.priority"
                      title="Priority"
                    />
                    <b-col>
                      <b-form-group label="Reason" label-for="select-1">
                        <div
                          class="form-control d-flex align-items-center justify-content-center"
                          style="gap: 3px"
                          :class="skin == 'dark' ? 'disabled' : ''"
                        >
                          <template
                            v-for="(reason, index) in reasonData(selectedClaim)"
                          >
                            <b-badge
                              v-if="index < 2"
                              :key="reason.id"
                              :style="{
                                backgroundColor: 'rgba(0, 144, 231, 0.12)',
                              }"
                              class="px-1 text-primary"
                            >
                              {{ reason.name }}
                            </b-badge>
                          </template>
                          <template v-if="reasonData(selectedClaim).length > 1">
                            <feather-icon
                              icon="EyeIcon"
                              size="16"
                              class="text-primary"
                              id="tooltip-1"
                            />
                            <b-tooltip
                              :target="'tooltip-1'"
                              triggers="hover"
                              placement="top"
                            >
                              <template
                                v-for="reason in reasonData(selectedClaim)"
                              >
                                <b-badge
                                  :key="reason.id"
                                  variant="light-primary"
                                  class="d-block mb-1s"
                                >
                                  {{ reason.name }}
                                </b-badge>
                              </template>
                            </b-tooltip>
                          </template>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    id="input-textarea"
                    label="Claim description"
                    label-for="textarea-description"
                  >
                    <b-form-textarea
                      v-model="selectedClaim.description"
                      id="textarea-no-resize"
                      placeholder="enter claim description"
                      rows="4"
                      no-resize
                      disabled
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <LevelZero
                v-if="formSolution.claimLevelId === 1"
                :formSolution="formSolution"
                :dataSolutionsClaims="dataSolutionsClaims"
                :optionsSolutions="optionsSolutions"
              />
              <StepLevel
                :onlyStep="true"
                :dataSolutionsForClaim="dataSolutionsForClaim"
                :selectedClaim="selectedClaim"
                v-if="formSolution.claimLevelId !== 1"
              />
              <hr />
              <LevelOneAndTwo
                :formSolution="formSolution"
                :dataSolutionsClaims.sync="dataSolutionsClaims"
                :optionsSolutions="optionsSolutions"
                @updateFormSolution="updateFormSolution"
                v-if="
                  formSolution.claimLevelId === 2 ||
                  formSolution.claimLevelId === 3
                "
              />

              <LevelThree
                :dataSolutionsForClaim="dataSolutionsForClaim"
                :dataSolutionsClaims="dataSolutionsClaims"
                :optionsSolutions="optionsSolutions"
                :formSolution="formSolution"
                @updateFormSolution="updateFormSolution"
                v-if="formSolution.claimLevelId === 4"
              />
              <b-row class="pb-3">
                <b-col md="3" v-if="formSolution.statusSolution == 'SOLVED'">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="select-sanctions"
                    rules="required"
                  >
                    <b-form-group label="Requires a sanction?">
                      <b-form-radio-group
                        id="radio-slots"
                        v-model="formSolution.requiredSanction"
                        :options="optionsSanction"
                        name="radio-options-slots"
                        :state="errors[0] ? false : null"
                      >
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="errors[0] ? false : null"
                        >select option</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="4" v-if="formSolution.requiredSanction == 'SI'">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="select-sanctions"
                    rules="required"
                  >
                    <b-form-group
                      id="sanction-1"
                      label="Select sanction"
                      label-for="sanction-1"
                      label-class="text-uppercase text-primary fw-700"
                    >
                      <v-select
                        v-model="formSolution.sanctionId"
                        label="name"
                        :options="dataSanctions"
                        :reduce="(e) => e.id"
                        id="select-sanction"
                        :class="errors[0] ? 'border-danger' : null"
                      ></v-select>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
    <template #modal-footer>
      <b-button variant="secondary" @click="closeMe">Cancel</b-button>
      <b-button
        :disabled="loading"
        variant="primary"
        id="save-solution"
        @click.prevent="SaveSolution()"
        >Save</b-button
      >
    </template>
  </b-modal>
</template>
<script>
import ClaimV2Service from "../../services/claims-v2.service";
import itemValue from "../itemValue.vue";
import { mapActions, mapGetters } from "vuex";
import LevelZero from "./LevelZero.vue";
import LevelOneAndTwo from "./LevelOneAndTwo.vue";
import StepLevel from "../Stepers/StepLevel.vue";
import LevelThree from "./LevelThree.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  name: "ModalSolutionClaim",
  components: {
    itemValue,
    LevelZero,
    LevelOneAndTwo,
    StepLevel,
    LevelThree,
    StatusAccount,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    selectedClaim: {
      type: Object,
      required: true,
      default: () => undefined,
    },
  },

  data() {
    return {
      optionsSolutions: [
        { text: "SOLVED", value: "SOLVED" },
        { text: "NOT SOLVED", value: "NOT-SOLVED" },
      ],
      optionsSanction: [
        { text: "YES", value: "SI" },
        { text: "NO", value: "NO" },
      ],
      activeModal: false,
      loading: false,
      formSolution: {
        statusSolution: null,
        claimId: 0,
        description: "",
        claimLevelId: 0,
        claimStatus: "",
        solutions: [],
        email: "",
        user_id: 0,
        scaleClaimLevelRefound: null,
        statuRefound: null,
        levelSelectNotSolved: null,
        requiredSanction: null,
        sanctionId: null,
        url_survey: null,
        client_account_id: null,
        nextModule: null,
        moduleId: 0,
        amountRefund: 0,
      },
      dataSolutionsClaims: [],
      dataSolutionsForClaim: [],
      dataSanctions: [],
      titleSwalAlert: "",
      levels: {
        2: "level 1",
        3: "level 2",
        4: "level 3",
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
    }),
    colorStatus() {
      let selected;
      switch (this.selectedClaim.cliente_account_status) {
        case 1:
          selected = "#00CC00"; //green
          break;
        case 2:
          selected = "yellow";
          break;
        case 3:
          selected = "blue";
          break;
        case 4:
          selected = "red";
          break;
        case 5:
          selected = "red";
          break;
        case 6:
          selected = "red";
          break;
      }
      return selected;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    selectedClaim: {
      async handler(value) {
        if (value) {
          this.formSolution.levelSelect = value.level_id;
          this.formSolution.claimId = value.claim_id;
          this.formSolution.claimLevelId = value.level_id;
          this.formSolution.claimStatus = value.status;
          this.formSolution.email = value.email;
          this.formSolution.client_account_id = value.account_id;
          this.formSolution.moduleId = this.moduleId;
          const { data } = await ClaimV2Service.getSolutionsForClaimQuality(
            value.claim_id
          );
          this.dataSolutionsForClaim = data;
          await this.getSolutionsClaimsQuality();
        }
      },
      deep: true,
      immediate: true,
    },
    "formSolution.claimLevelId": {
      handler(value) {
        if (value === 1) {
          this.formSolution.nextModule = 18;
        }
        this.getSanctionsForLevel();
      },
      immediate: true,
    },
    "formSolution.statusSolution": {
      handler(value) {
        this.formSolution.requiredSanction = null;
      },
    },
    "formSolution.requiredSanction": {
      handler(value) {
        this.formSolution.sanctionId = null;
      },
      deep: true,
    },
    "formSolution.levelSelectNotSolved": {
      handler(value) {
        if (value === 3) {
          this.formSolution.nextModule = 18;
        } else if (value === 4) {
          this.formSolution.nextModule = 16;
        } else {
          this.formSolution.nextModule = null;
        }
      },
      deep: true,
    },
    formSolution: {
      handler(value) {
        let levelNext = 0;
        const {
          levelSelect,
          statusSolution,
          statuRefound,
          levelSelectNotSolved,
        } = value;
        const isLevelSelectOne = levelSelect === 1;
        const isLevelSelectTwoOrThree = levelSelect === 2 || levelSelect === 3;
        const isStatuRefound = statuRefound;

        levelNext = isLevelSelectOne
          ? 2
          : isLevelSelectTwoOrThree
          ? isStatuRefound
            ? 4
            : levelSelectNotSolved
          : levelSelectNotSolved;

        const statusClaim = levelSelect === 4 ? "reactivate" : "escalate";
        const clientName = this.selectedClaim.client;
        const nextLevel = this.levels[levelNext];

        if (statusSolution === "NOT-SOLVED" || isStatuRefound) {
          this.titleSwalAlert = `Are you sure to ${statusClaim} the ${clientName} customer claim to ${nextLevel}?`;
        } else {
          const textStatusHold =
            "Are you sure to change the claim status to hold?";
          const textSolved = `Are you sure to give a solution to the client ${clientName}'s claim?`;
          this.titleSwalAlert =
            isStatuRefound != null && !isStatuRefound
              ? textStatusHold
              : textSolved;
        }
      },
      deep: true,
      immediate: true,
    },
    "formSolution.solutions": {
      handler(value) {
        /** buscar  */
        const containStatusHold = value.includes(1);
        this.formSolution.nextModule = containStatusHold ? 16 : null;
      },
      deep: true,
    },
    "formSolution.statuRefound": {
      handler(value) {
        const containStatusHold = this.formSolution.solutions.includes(1);
        this.formSolution.nextModule = containStatusHold && value ? 16 : null;
      },
      deep: true,
    },
  },
  async created() {
    this.activeModal = this.show;
    this.formSolution.user_id = this.currentUser.user_id;
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "ClaimsV2Store/CHANGE_REFRESH_TABLE",
      A_PENDING_CLAIMS: "ClaimsStore/A_PENDING_CLAIMS",
    }),
    closeMe() {
      this.activeModal = false;
      this.$emit("close");
    },
    handleOK(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.SaveSolution();
    },
    async SaveSolution() {
      try {
        let valid = await this.$refs.formObservableSolution.validate();
        if (!valid) throw new Error("fields required");
        const confirm = await this.showConfirmSwal(this.titleSwalAlert);
        if (confirm.isConfirmed) {
          this.loading = true;
          const { success, message } = await ClaimV2Service.storeAddSolution(
            this.formSolution
          );
          // console.log(this.formSolution);
          // const success = false

          if (!success) throw new Error(message);
          this.CHANGE_REFRESH_TABLE(true);
          await this.A_PENDING_CLAIMS(this.moduleId);
          this.closeMe();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            message
          );
        }
      } catch (ex) {
        this.loading = false;
        this.showToast("danger", "top-right", "Failed!", "XIcon", ex.message);
      }
    },
    async getSolutionsClaimsQuality() {
      const { data } = await ClaimV2Service.getSolutionsClaimsQuality();
      let levelId = this.selectedClaim.level_id;
      this.dataSolutionsClaims =
        levelId == 1 || levelId == 4 ? data.filter((x) => x.id !== 1) : data;
    },
    updateFormSolution(value) {
      this.formSolution = value;
    },
    async getSanctionsForLevel() {
      try {
        const { data } = await ClaimV2Service.getSanctionForLevelClaim(
          this.formSolution.claimLevelId
        );
        this.dataSanctions = data;
      } catch (ex) {
        this.showToast("danger", "top-right", "Faileds!", "XIcon", ex.message);
      }
    },
    reasonData(item) {
      return JSON.parse(item.reasons);
    },
  },
};
</script>
<style>
.fw-700 {
  font-weight: 700 !important;
}
.v-container {
  width: 100%;
}

.v-progressbar {
  counter-reset: step;
}
.v-progressbar li {
  list-style: none;
  display: inline-block;
  width: 30.33%;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.v-progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ddd;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
}
.v-progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.v-progressbar li:first-child:after {
  content: none;
}
.v-progressbar li.v-active {
  color: green;
}
.v-progressbar li.v-active:before {
  border-color: green;
}
.v-progressbar li.v-active + li:after {
  background-color: green;
}

div.form-control.disabled {
  opacity: 0.5;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.4);
}
</style>
