<template>
  <b-modal
    v-model="isActive"
    title="Assign a survey to claim"
    title-tag="h2"
    @hidden="closeMe(false)"
    hide-footer
  >
    <h2 class="text-center pt-1 font-weight-bold">Select one survey</h2>
    <hr>

    <b-table
      :items="surveys"
      :fields="fields"
      selected-variant="dark"
      selectable
      select-mode="single"
      hover
      responsive
      @row-selected="selectASurvey"
    >
      <template #cell(title)="{ value }">
        {{ value }}
      </template>
      <template #cell(created_by)="{ item, value }">
        <span>{{ value }}</span> <br />
        <span>{{ item.created_at | myGlobalDay }}</span>
      </template>
      <template #cell(count_questions)="{ value }">
        <div class="text-center">
          <b-badge variant="primary" style="font-size: 16px">
            {{ value }}
          </b-badge>
        </div>
      </template>

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <div class="d-flex justify-content-center pt-1">
            <FeatherIcon icon="CheckIcon" size="16" class="text-primary" />
          </div>
        </template>
      </template>
    </b-table>
    <div class="d-flex justify-content-center align-items-center mb-2">
      <b-button
        @click="saveSelect"
        variant="success"
        size="lg"
        :disabled="!isActiveSaveButton"
      >
        ASSIGN
        <FeatherIcon icon="CheckIcon" size="22" />
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import SettingsService from "@/views/quality/views/settings/service/survey.service.js"
import { mapGetters } from "vuex"

export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    claim_id: {
      type: Number,
      required: true,
    },
  },
  async created() {
    await this.getSurveys()
  },
  mounted() {
    this.isActive = this.active
  },
  data() {
    return {
      isActive: false,
      surveys: [],
      FilterSlot: {
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: null,
        toPage: null,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
        isBusy: false,
      },
      rowSelected: {},
    }
  },
  methods: {
    openMe() {
      this.isActive = this.active
    },
    closeMe(refresh = false) {
      this.$emit("close", refresh)
      this.isActive = false
    },
    async getSurveys() {
      this.isBusy = true
      const { data } =
        await SettingsService.getAllSurveys(this.params)

      this.isBusy = false

      this.surveys = data
    },
    selectASurvey(data) {
      this.rowSelected = data.length ? data[0] : {}
    },
    async saveSelect() {
      const result = await SettingsService.assignSurveyToClaim(
        this.assignSurveyParams
      )

      if (result == 200) {
        this.showSuccessSwal()
        this.closeMe(true)
      } else {
        this.showErrorSwal()
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    params() {
      return {
        search: this.FilterSlot.filterPrincipal.model,
        per_page: this.FilterSlot.paginate.perPage ?? 10,
        page: this.FilterSlot.paginate.currentPage,
      }
    },
    fields() {
      return [
        {
          key: "selected",
          label: "Selected",
          thClass: "text-center",
        },
        {
          key: "title",
          label: "Title",
        },
        {
          key: "count_questions",
          label: "# of questions",
          thClass: "text-center",
        },
        {
          key: "created_by",
          label: "created by",
        },
      ]
    },
    isActiveSaveButton() {
      const keys = Object.keys(this.rowSelected)

      return keys.some((key) => key == "survey_id")
    },
    assignSurveyParams() {
      return {
        claim_id: this.claim_id,
        survey_id: this.rowSelected.survey_id,
        user_id: this.currentUser.user_id,
      }
    },
  },
}
</script>