var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"selectSolutions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"solution-1","label":"Solutions offered","label-for":"select-1","label-class":"text-uppercase text-primary fw-700"}},[_c('v-select',{class:errors[0] ? 'border-danger' : null,attrs:{"multiple":"","label":"name","options":_vm.dataSolutionsClaims,"reduce":function (e) { return e.id; },"id":"select-solutions"},model:{value:(_vm.formSolution.solutions),callback:function ($$v) {_vm.$set(_vm.formSolution, "solutions", $$v)},expression:"formSolution.solutions"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"optionsSolutions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Result","label-class":"text-uppercase text-primary fw-700"}},[_c('b-form-radio-group',{staticClass:"custom-radio-group-claims-before",class:_vm.solutionClass,attrs:{"id":"result-radio","options":_vm.optionsSolutions,"name":"result-radio","state":errors[0] ? false : null},model:{value:(_vm.formSolution.statusSolution),callback:function ($$v) {_vm.$set(_vm.formSolution, "statusSolution", $$v)},expression:"formSolution.statusSolution"}},[_c('b-form-invalid-feedback',{attrs:{"state":errors[0] ? false : null}},[_vm._v("select an option")])],1)],1)]}}])})],1),(_vm.formSolution.levelSelectNotSolved)?_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"optionsLevels","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Send To","label-class":"text-uppercase text-primary fw-700"}},[_c('b-form-radio-group',{attrs:{"id":"optionsLevelsPrevious","options":_vm.PreviousLevels,"name":"result-radio-levelto","state":errors[0] ? false : null},model:{value:(_vm.formSolution.levelSelectNotSolved),callback:function ($$v) {_vm.$set(_vm.formSolution, "levelSelectNotSolved", $$v)},expression:"formSolution.levelSelectNotSolved"}},[_c('b-form-invalid-feedback',{attrs:{"state":errors[0] ? false : null}},[_vm._v("select an option")])],1)],1)]}}],null,false,168548394)})],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"descriptionSolution","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"description","label":"Description","label-for":"description-1","label-class":"text-uppercase text-primary fw-700"}},[_c('b-form-textarea',{attrs:{"id":"description-1","placeholder":"Enter something...","rows":"3","max-rows":"6","no-resize":"","state":errors[0] ? false : null},model:{value:(_vm.formSolution.description),callback:function ($$v) {_vm.$set(_vm.formSolution, "description", $$v)},expression:"formSolution.description"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }