<template>
    <b-modal v-model="showModal" size="lg" hide-footer title="SURVEY RESULT" @hide="closeModal">
        <div class="mb-2">
            <b-row>
                <b-col  cols="12" md="6" lg="3">
                    <span class="title-tag">CLIENT NAME</span>
                    <div class="border border-primary rounded">
                        <p
                        class="text-primary d-flex align-items-center justify-content-center my-1s"
                        style="gap: 5px"
                        >
                        {{clientName}}
                        </p>
                    </div>
                </b-col>

                <b-col  cols="12" md="6" lg="3" class="pt-md-0 pt-1">
                    <span class="title-tag">STATUS</span>
                    <div class="border border-primary rounded">
                        <p
                        class="text-primary d-flex align-items-center justify-content-center my-1s"
                        style="gap: 5px"
                        >
                        {{statusSurvey}}
                        </p>
                    </div>
                </b-col>

                <b-col  cols="12" md="6" lg="3" class="pt-md-0 pt-1">
                    <span class="title-tag">REPLY DATE</span>
                    <div class="border border-primary rounded">
                        <p
                        class="text-primary d-flex align-items-center justify-content-center my-1s"
                        style="gap: 5px"
                        >
                        {{ replyDateSurvey | myGlobalDay }}
                        </p>
                    </div>
                </b-col>

                <b-col  cols="12" md="6" lg="3" class="pt-md-0 pt-1">
                    <span class="title-tag">SURVEY TITLE</span>
                    <div class="border border-primary rounded">
                        <p
                        class="text-primary d-flex align-items-center justify-content-center my-1s"
                        style="gap: 5px"
                        >
                        {{ surveyTitle }}
                        </p>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="table-responsive">
            <b-table
            ref="trackingTable"
            :items="myProvider"
            :fields="Fields"
            primary-key="id"
            responsive="sm"
            show-empty
            sticky-header="50vh"
            class="blue-scrollbar"
            :busy="isBusy"
            no-provider-filtering
            >
                <template #cell(answer)="data">
                    <div :class="answerColor(data.item.answer_id)" class="">
                        {{data.item.answer}}
                    </div>
                </template>
                <template #cell(question)="data">
                        {{data.item.english}}
                </template>
                <template #cell(weight)="data">
                    <div class="text-center h3">
                        {{data.item.weight}}
                    </div>
                </template>
                <template #cell(icon)="data">
                    <div class="h2">
                        {{answerIcon(data.item.answer_id)}}
                    </div>
                </template>
            </b-table>
            <hr>
            <div class="d-flex justify-content-end">
                <p class="border p-1 rounded">
                    RESULT SURVEY:  
                    <b-badge :variant="resultSurvey == 'SATISFIED'? 'light-success' : 'light-danger'">
                    {{ resultSurvey }}
                    </b-badge>
                </p>
            </div>
        </div>
    </b-modal>
</template>
<script>
import SurveyService from "@/views/commons/components/claims_v2/services/survey.service"
import Fields from "@/views/commons/components/claims_v2/data/Level/fields.surveyResults.js"
export default {
    data() {
        return {
           showModal: false,
           Fields,
           isBusy: false,
           statusSurvey: "",
           resultSurvey: "",
           replyDateSurvey: "",
           surveyTitle: "",
        }
    },
    props: {
        claimId: Number,
        clientName: String,
    },
    created(){
        this.showModal = true;
    },
    methods: {
        async myProvider(){
            const formdata = {
                "claimId": this.claimId
            }
            const data = await SurveyService.getSurveyResults(formdata);
            this.statusSurvey = data[0].status
            this.resultSurvey = data[0].result
            this.replyDateSurvey = data[0].reply_date
            this.surveyTitle = data[0].survey_title
            return data;
        },
        closeModal(){
            this.$emit("close");
        },
        answerColor(answerId){
            let colors = {
                1: "text-danger",
                2: "text-danger",
                3: "text-warning",
                4: "text-success",
                5: "text-success"
            }
            return colors[answerId];
        },
        answerIcon(answerId){
            let icons = {
                1: "😡",
                2: "🙁",
                3: "😐",
                4: "🙂",
                5: "🤩"
            }
            return icons[answerId];
        }
    }
   
}
</script>