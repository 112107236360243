const { amgApi } = require("@/service/axios");

class ClaimV2Service {
  async getClaimData(params) {
    const { data } = await amgApi.get("commons/claims-v2/get-for-level", {
      params,
    });
    return data;
  }
 
  async getTrackingclaim(params){
    try {
      const dataset = await amgApi.post("commons/claims-v2/get-tracking-claim", params);
      const { data } = dataset.data
      return data;
    } catch (error) {
      throw error;
    }
  }

  async sendSms(params){
    const data = await amgApi.post("commons/claims-v2/send-sms", params);
    return data;
  }

  async sendContactEmail(params) {
    try {
      const data = await amgApi.post('commons/claims-v2/send-contact-email', params);
      return data
    } catch (error) {
      throw error;
    }
  }

  async getTrackingContact(params){
    try {
      const {data} = await amgApi.post('commons/claims-v2/get-tracking-contact', params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  
}
export default new ClaimV2Service();
