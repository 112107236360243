<template>
  <b-modal
    size="xmd"
    v-model="onControl"
    :title="status == 1 ? 'Pending inquiries' : 'Solved inquiries'"
    @hidden="closeMe"
    centered
    hide-footer
  >
    <div>
      <b-row>
        <b-col>
          <span class="title-tag">CLIENT NAME</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ clientName }}
            </p>
          </div>
        </b-col>
        <b-col>
          <span class="title-tag">Advisor</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ advisorName || "None" }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="inquiry-details-list"
      ref="inquiry-details-table"
      no-border-collapse
      class="position-relative mt-2"
      :fields="myFields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="items"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(created_at)="data">
        <span class="d-block">{{ data.item.created_by }}</span>
        <span class="d-block">{{ data.item.created_at | myGlobalDay }} </span>
      </template>
      <template #cell(solution)="{ item }">
        <feather-icon
          icon="InfoIcon"
          size="20"
          class="text-info cursor-pointer"
          v-b-tooltip.hover.top="`View solution`"
          @click="openInquiryFilesModal(item)"
        />
      </template>
      <template #cell(updated_at)="data">
        <span class="d-block">{{ data.item.updated_by }}</span>
        <span class="d-block">{{ data.item.updated_at | myGlobalDay }} </span>
      </template>
      <template v-slot:cell(views)="{ item }">
        <feather-icon
          v-if="item.status_view === 1"
          class="text-success"
          size="20"
          v-b-tooltip.hover.left="'Viewed'"
          icon="CheckIcon"
        />

        <feather-icon
          v-else
          size="20"
          class="text-warning parpadea"
          icon="CheckIcon"
        />
      </template>
    </b-table>

    <inquiry-files-modal
      v-if="showInquiryFilesModal"
      :inquiryId="selectedInquiry.id"
      :inquiryText="selectedInquiry.inquiry"
      :solutionText="selectedInquiry.solution"
      :defaultView="true"
      @close="closeInquiryFilesModal"
    />
  </b-modal>
</template>

<script>
import dataFields from "@/views/commons/components/claims_v2/views/claim-inquiries/data/fields.inquiry-details.js";
import InquiryClaimService from "@/views/commons/components/claims_v2/views/claim-inquiries/services/inquiry-claim.service.js";
import InquiryFilesModal from "@/views/commons/components/claims_v2/views/claim-inquiries/views/InquiryFilesModal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    status: {
      type: Number,
      required: true,
    },
    claimSelected: {
      type: Number,
      required: true,
    },
    advisorName: {
      type: String,
      required: false,
    },
    clientName: {
      type: String,
      required: false,
    },
  },
  components: {
    InquiryFilesModal,
  },
  data() {
    return {
      dataFields,
      onControl: false,
      isBusy: false,
      items: [],
      selectedInquiry: null,
      showInquiryFilesModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    myFields: function () {
      this.dataFields[2].visible = this.status == 2;
      this.dataFields[3].visible = this.status == 2;
      this.dataFields[4].visible = this.status == 2;
      return this.dataFields.filter((field) => field.visible);
    },
  },
  created() {
    this.onControl = true;
    this.myProvider();
  },
  methods: {
    ...mapActions({
      A_PENDING_INQUIRIES: "ClaimsStore/A_PENDING_INQUIRIES",
    }),
    closeMe() {
      this.$emit("close");
    },
    async myProvider() {
      this.isBusy = true;
      const parameters = {
        status: this.status,
        claimId: this.claimSelected,
        userId: this.currentUser.user_id,
      };

      try {
        const { data } = await InquiryClaimService.getInquiriesByClaim(
          parameters
        );
        this.isBusy = false;
        this.items = data;
      } catch (error) {
        console.error(error);
      }
    },
    async openInquiryFilesModal(item) {
      this.selectedInquiry = item;
      if (item.status_view !== 1) {
        await this.changeView(item);
      }
      this.showInquiryFilesModal = true;
      item.status_view = 1;
    },
    async closeInquiryFilesModal() {
      await this.A_PENDING_INQUIRIES();
      this.showInquiryFilesModal = false;
    },

    async changeView(item) {
      const params = { id: item.id, user_id: this.currentUser.user_id };
      await InquiryClaimService.changeInquiryStatusView(params);
    },
  },
};
</script>

<style scoped>
.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
