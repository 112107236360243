<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col :md="option1Selected ? 8 : 6">
            <ValidationProvider
              v-slot="{ errors }"
              name="selectSolutions"
              rules="required"
            >
              <b-form-group
                id="solution-1"
                label="SOLUTIONS OFFERED"
                label-for="select-1"
                label-class="text-uppercase text-primary fw-700"
              >
                <v-select
                  v-model="formSolution.solutions"
                  multiple
                  label="name"
                  :options="dataSolutionsClaims"
                  :reduce="(e) => e.id"
                  id="select-solutions"
                  :class="errors[0] ? 'border-danger' : null"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col v-if="!option1Selected" :md="!option1Selected ? '3' : null">
            <ValidationProvider
              v-slot="{ errors }"
              name="optionsSolutions"
              rules="required"
            >
              <b-form-group label="Result">
                <b-form-radio-group
                  id="result-radio"
                  v-model="formSolution.statusSolution"
                  :options="optionsSolutions"
                  name="result-radio"
                  :state="errors[0] ? false : null"
                  :class="solutionClass"
                  class="custom-radio-group-claims-before"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null"
                    >select an option</b-form-invalid-feedback
                  >
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col
            v-if="!option1Selected && showOptionsLevels"
            :md="!option1Selected && showOptionsLevels ? '3' : null"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="optionsSolutionsLevels"
              rules="required"
            >
              <b-form-group label="Send to">
                <b-form-radio-group
                  id="send-to-level"
                  v-model="levelSelected"
                  :options="LevelsData"
                  name="send-to-level"
                  :state="errors[0] ? false : null"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null"
                    >select an level</b-form-invalid-feedback
                  >
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col v-if="option1Selected" cols="2" >
            <ValidationProvider
              v-slot="{ errors }"
              name="optionRefound"
              rules="required"
            >
              <b-form-group label="Is there a refund?" label-class="text-uppercase"> 
                <b-form-radio-group
                  id="selected-refound"
                  v-model="refundSelected"
                  :options="optionRefound"
                  name="selected-refound"
                  :state="errors[0] ? false : null"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null"
                    >select an option</b-form-invalid-feedback
                  >
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cools="4" v-if="refundSelected">
            <ValidationProvider
              v-slot="{ errors }"
              name="amountRefund"
              rules="required"
            >
              <b-form-group label="Refund amount" label-class="text-uppercase"> 
                  <money
                    v-bind="vMoney"
                    v-model="formSolution.amountRefund"
                    class="form-control"
                    :class="errors[0] ? 'is-invalid' : null"
                  /> 
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="descriptionSolution"
              rules="required"
            >
              <b-form-group
                id="description"
                label="Description"
                label-for="description-1"
              >
                <b-form-textarea
                  id="description-1"
                  v-model="formSolution.description"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                  no-resize
                  :state="errors[0] ? false : null"
                ></b-form-textarea>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import VMoney from 'v-money'
export default {
  name: "LevelOneAndTwo",
  directives: { money: VMoney },
  props: {
    formSolution: {
      type: Object,
      required: true,
    },
    dataSolutionsClaims: {
      type: Array,
      required: true,
      default: () => [],
    },
    optionsSolutions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      option1Selected: false,
      previousSelection: [],
      showOptionsLevels: false,
      Levels: [
        { value: 1, text: "Level 0", disabled: false, visible: false },
        { value: 2, text: "Level 1", disabled: false, visible: true },
        { value: 3, text: "Level 2", disabled: false, visible: true },
        { value: 4, text: "Level 3", disabled: false, visible: true },
      ],
      levelSelected: "",
      refundSelected: null,
      loadingSendInquiry: false,
      optionRefound: [
        { text: "YES", value: true },
        { text: "NO", value: false },
      ],
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
    };
  },
  computed: {
    LevelsData() {
      return this.Levels.filter((x) => x.visible);
    },
    solutionClass() {
      if (
        this.formSolution.statusSolution === "SOLVED" ||
        this.formSolution.statusSolution === "NOT-SOLVED"
      ) {
        return "custom-radio-group-claims";
      } else {
        return "";
      }
    },
  },
  watch: {
    "formSolution.solutions": {
      handler(value) {
        let verified = value.includes(1);
        if (verified) {
          this.option1Selected = true;
          this.updateFieldFormulario(null);
        } else {
          this.option1Selected = false;
          this.refundSelected = null;
          if (!this.formSolution.statusSolution) {
            this.updateFieldFormulario(null);
          }
        }

        if (this.refundSelected) {
          this.updateFieldFormulario(true, 4);
        }
      },
      deep: true,
    },
    "formSolution.statusSolution": {
      handler(value) {
        this.levelSelected = null;
        this.showOptionsLevels = false;
        this.formSolution.requiredSanction = null;
        this.$emit("updateFormSolution", this.formSolution);
        if (value === "NOT-SOLVED") {
          this.showOptionsLevels = true;
          this.getLevelsTo();
        }
      },
    },
    refundSelected(value) {
      this.updateFieldFormulario(value);
      if (value) this.updateFieldFormulario(true, 4);
    },
    levelSelected(value) {
      this.formSolution.levelSelectNotSolved = value;
      this.$emit("updateFormSolution", this.formSolution);
    },
  },
  methods: {
    updateFieldFormulario(statuHold, level = null) {
      this.formSolution.scaleClaimLevelRefound = level;
      this.formSolution.statusSolution = null;
      this.formSolution.statuRefound = statuHold; 
      this.$emit("updateFormSolution", this.formSolution);
    },
    getLevelsTo() {
      if (this.formSolution.claimLevelId === 3) {
        this.levelSelected = 4;
        this.Levels = this.Levels.map((item) => {
          if (item.value != 4) {
            return { ...item, visible: false };
          } else {
            return { ...item, visible: true, disabled: true };
          }
        });
      } else {
        this.Levels.forEach((item) => {
          if (item.value === this.formSolution.claimLevelId) {
            item.visible = false;
          }
        });
      }
    },
  },
};
</script>
<style>
.col-form-label.text-transparent {
  color: transparent !important;
}
.custom-radio-group-claims
  .custom-control-input:checked[value="SOLVED"]
  ~ .custom-control-label::before {
  border-color: #28a745;
  background-color: #28a745;
}

.custom-radio-group-claims
  .custom-control-input:checked[value="NOT-SOLVED"]
  ~ .custom-control-label::before {
  border-color: #dc3545;
  background-color: #dc3545;
}

.custom-radio-group-claims-before
  .custom-control-label[for="result-radio_BV_option_0"] {
  color: #28a745;
  font-weight: bold;
}

.custom-radio-group-claims-before
  .custom-control-label[for="result-radio_BV_option_1"] {
  color: #dc3545;
  font-weight: bold;
}
</style>