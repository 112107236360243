<template>
  <div>
    <b-row>
      <b-col md="6">
        <ValidationProvider
          v-slot="{ errors }"
          name="selectSolutions"
          rules="required"
        >
          <b-form-group
            id="solution-1"
            label="Solutions offered"
            label-for="select-1"
            label-class="text-uppercase text-primary fw-700"
          >
            <v-select
              v-model="formSolution.solutions"
              multiple
              label="name"
              :options="dataSolutionsClaims"
              :reduce="(e) => e.id"
              id="select-solutions"
              :class="errors[0] ? 'border-danger' : null"
            ></v-select>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="3">
        <ValidationProvider
          v-slot="{ errors }"
          name="optionsSolutions"
          rules="required"
        >
          <b-form-group
            label="Result"
            label-class="text-uppercase text-primary fw-700"
          >
            <b-form-radio-group
              id="result-radio"
              v-model="formSolution.statusSolution"
              :options="optionsSolutions"
              name="result-radio"
              :state="errors[0] ? false : null"
              :class="solutionClass"
              class="custom-radio-group-claims-before"
            >
              <b-form-invalid-feedback :state="errors[0] ? false : null"
                >select an option</b-form-invalid-feedback
              >
            </b-form-radio-group>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="3" v-if="formSolution.levelSelectNotSolved">
        <ValidationProvider
          v-slot="{ errors }"
          name="optionsLevels"
          rules="required"
        >
          <b-form-group
            label="Send To"
            label-class="text-uppercase text-primary fw-700"
          >
            <b-form-radio-group
              id="optionsLevelsPrevious"
              v-model="formSolution.levelSelectNotSolved"
              :options="PreviousLevels"
              name="result-radio-levelto"
              :state="errors[0] ? false : null"
            >
              <b-form-invalid-feedback :state="errors[0] ? false : null"
                >select an option</b-form-invalid-feedback
              >
            </b-form-radio-group>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="12">
        <ValidationProvider
          v-slot="{ errors }"
          name="descriptionSolution"
          rules="required"
        >
          <b-form-group
            id="description"
            label="Description"
            label-for="description-1"
            label-class="text-uppercase text-primary fw-700"
          >
            <b-form-textarea
              id="description-1"
              v-model="formSolution.description"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
              no-resize
              :state="errors[0] ? false : null"
            ></b-form-textarea>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "LevelThree",
  props: {
    formSolution: {
      type: Object,
      required: true,
    },
    optionsSolutions: {
      type: Array,
      required: true,
      default: () => [],
    },
    dataSolutionsClaims: {
      type: Array,
      required: true,
      default: () => [],
    },
    dataSolutionsForClaim: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      Levels: [
        { value: 1, text: "Level 0", disabled: true, visible: false },
        { value: 2, text: "Level 1", disabled: false, visible: false },
        { value: 3, text: "Level 2", disabled: false, visible: false },
        { value: 4, text: "Level 3", disabled: true, visible: false },
      ],
    };
  },
  watch: {
    "formSolution.statusSolution": {
      handler(value) {
        if (value === "NOT-SOLVED") {
          if (value.length > 0) {
            let data = this.dataSolutionsForClaim;
            this.formSolution.levelSelectNotSolved =
              data[data.length - 1].claim_current_level;
            this.$emit("updateFormSolution", this.formSolution);
          }
        } else {
          this.formSolution.levelSelectNotSolved = null;
          this.$emit("updateFormSolution", this.formSolution);
        }
      },
      deep: true,
    },

    "formSolution.levelSelectNotSolved": {
      handler(value) {
        const newLevel = this.Levels.map((x) => {
          if (x.value == 2 || x.value == 3) {
            x.visible = true;
          }
          return x;
        });
        this.Levels = newLevel;
      },
      deep: true,
    },
  },
  computed: {
    PreviousLevels() {
      return this.Levels.filter((x) => x.visible);
    },
    solutionClass() {
      if (
        this.formSolution.statusSolution === "SOLVED" ||
        this.formSolution.statusSolution === "NOT-SOLVED"
      ) {
        return "custom-radio-group-claims";
      } else {
        return "";
      }
    },
  },
  methods: {},
};
</script>

<style>
.custom-radio-group-claims
  .custom-control-input:checked[value="SOLVED"]
  ~ .custom-control-label::before {
  border-color: #28a745;
  background-color: #28a745;
}

.custom-radio-group-claims
  .custom-control-input:checked[value="NOT-SOLVED"]
  ~ .custom-control-label::before {
  border-color: #dc3545;
  background-color: #dc3545;
}

.custom-radio-group-claims-before
  .custom-control-label[for="radio-group-1_BV_option_0"] {
  color: #28a745;
  font-weight: bold;
}

.custom-radio-group-claims-before
  .custom-control-label[for="radio-group-1_BV_option_1"] {
  color: #dc3545;
  font-weight: bold;
}
</style>
