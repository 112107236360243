<template>
	<b-modal
		v-model="showModal"
		id="modal-entrances-tracking" 
		modal-class="modal-primary" 
		title="Tracking list" 
		title-tag="h3"
		header-bg-variant="primary"
		size="md"  
		hide-footer 
		@hidden="closeModal" 
		centered
	>
		<h5>Client</h5>
		<p class="rounded text-primary border-primary font-medium-1 text-center py-1">
			{{ client }}
		</p>
		<filter-slot
			:filter="[]"
			:total-rows="FilterSlot.totalRows"
			:filterSearch="FilterSlot.filterSearch"
			:paginate="FilterSlot.paginate"
			:start-page="FilterSlot.startPage"
			:startRow="FilterSlot.startRow"
      		:endRow="FilterSlot.endRow"
			:to-page="FilterSlot.toPage"
			:filter-principal="FilterSlot.filterPrincipal"
			@reload="$refs['trackingTable'].refresh()"
		>
			<b-table
				slot="table"
				responsive
				:items="myProvider" 
				ref="trackingTable"
				small
				:fields="fields"
				sticky-header="50vh"
				:busy="isBusy"
				show-empty
				no-provider-filtering
				:per-page="FilterSlot.paginate.perPage"
        		:current-page="FilterSlot.paginate.currentPage"
			>
				<template #cell(user)="data">
					{{data.item.user}}
				</template>
				<template #cell(message)="{item}">
					 <div v-html="item.message" />
				</template>
				<template #cell(date)="data">
					{{data.item.date | myGlobalDay}}
				</template>
				<template #table-busy>
					<div class="text-center text-primary my-2">
					<b-spinner class="align-middle mr-1" />
					<strong>Loading ...</strong>
					</div>
				</template>
			</b-table>
		</filter-slot>
	</b-modal>
</template>

<script>
import ClaimService_v2 from "@/views/commons/components/claims_v2/services/claims-v2-level.service";
export default {
	props: {
		client: String,
		claimId: Number,
		type: String,
	},
	created() {
		this.showModal = true;
	},
	data() {
		return {
			showModal: false,
			fields: [
				{
					key:'user',
					label:'User',
				},
				{
					key:'message',
					label:'Description'
				},
				{
					key: 'date',
					label: 'Date',
				},
			],
			items: [],
			FilterSlot: {
				totalRows: 0,
				startRow: 0,
				endRow: 0,
				paginate: {
					currentPage: 1,
					perPage: 10,
				},
				startPage: null,
				toPage: null,
				filterPrincipal: {
					type: "input",
					inputType: "text",
					placeholder: "Name...",
					model: "",
				},
			},
			isBusy: false,
		}
	},
	methods: {
		async myProvider(){
			this.isBusy = true;
			let data;
			const params = {
				claimId: this.claimId,
				type: this.type,
				search: this.FilterSlot.filterPrincipal.model,
				page: this.FilterSlot.paginate.currentPage,
				perPage: this.FilterSlot.paginate.perPage,
			};
			try {
				data = await ClaimService_v2.getTrackingContact(params);
				this.startPage = data.from;
				this.FilterSlot.paginate.currentPage = data.current_page;
				this.FilterSlot.paginate.perPage = data.per_page;
				this.FilterSlot.totalRows = data.total;
				this.FilterSlot.toPage = data.to;
				this.FilterSlot.startRow = data.from;
				this.FilterSlot.endRow = data.to;
				this.isBusy = false;
			} catch (error) {
				console.log(error)
			}
			return data.data;
		},
		closeModal() {
			this.$emit('close')
		}
	}
}
</script>
<style scoped>
	.headerColor {
	background-color: #0090e7 !important
	}
</style>