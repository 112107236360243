const { amgApi } = require("@/service/axios");

class SettingsService {

    surveyRoute = 'quality/settings/survey'

    async getQuestionSurvey(){
        try {
            const {data} = await amgApi.get(`${this.surveyRoute}/get-questions`);
            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async updateQuestionSurvey(params){
        try {
            const {data} = await amgApi.post(`${this.surveyRoute}/update-question`, params);
            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async createQuestionSurvey(params){
        try {
            const {data} = await amgApi.post(`${this.surveyRoute}/create-question`, params);
            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async changeStatus(params){
        try {
            const {data} = await amgApi.post(`${this.surveyRoute}/change-status`, params);
            return data;
        } catch (error) {
            throw new Error(error);
        }
    }
    /**
     * @param params{
     *                 user_id : number
     *                 title : string
     *                 questions : question[]
     *              }
     * @type question {
     *      english: string,
     *      spanish: string
     *      weight: number
     * }
     *
     * @returns status: string | number
     */
    async createSurvey(params){
        try {
            const {status} = await amgApi.post(`${this.surveyRoute}/create` ,params);
            return status;
        } catch (error) {
            throw new Error(error);
            return error.message

        }
    }

    /**
     *
     * @param params {
     *                  search: string
     *                  per_page: number
     *                  page: number
     *              }
     * @returns array
     */
    async getAllSurveys(params){
        try {
            const {data} = await amgApi.post(`${this.surveyRoute}/get-all` ,params);
            return data;
        } catch (error) {
            throw new Error(error);
        }
    }
    /**
     *
     * @param params  {
     *              claim_id: number
     *              survey_id: number
     *              user_id: number
     *          }
     * @returns {
     *              success: boolean
     *          }
     */
    async assignSurveyToClaim(params){
        try {
            const {status} = await amgApi.post(`${this.surveyRoute}/assign-to-claim` ,params)
            return status
        } catch (error) {
            throw new Error(error)

            return error
        }
    }
}

export default new SettingsService();
