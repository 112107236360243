const { amgApi } = require("@/service/axios");

class SurveyService {

  async getSurveyResults(params) {
    const { data } = await amgApi.post("commons/claims-v2/survey-results", params);
    return data;
  }
 
}
export default new SurveyService();
