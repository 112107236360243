var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Assign a survey to claim","title-tag":"h2","hide-footer":""},on:{"hidden":function($event){return _vm.closeMe(false)}},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('h2',{staticClass:"text-center pt-1 font-weight-bold"},[_vm._v("Select one survey")]),_c('hr'),_c('b-table',{attrs:{"items":_vm.surveys,"fields":_vm.fields,"selected-variant":"dark","selectable":"","select-mode":"single","hover":"","responsive":""},on:{"row-selected":_vm.selectASurvey},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"cell(created_by)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.created_at)))])]}},{key:"cell(count_questions)",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"font-size":"16px"},attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(value)+" ")])],1)]}},{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('div',{staticClass:"d-flex justify-content-center pt-1"},[_c('FeatherIcon',{staticClass:"text-primary",attrs:{"icon":"CheckIcon","size":"16"}})],1)]:_vm._e()]}}])}),_c('div',{staticClass:"d-flex justify-content-center align-items-center mb-2"},[_c('b-button',{attrs:{"variant":"success","size":"lg","disabled":!_vm.isActiveSaveButton},on:{"click":_vm.saveSelect}},[_vm._v(" ASSIGN "),_c('FeatherIcon',{attrs:{"icon":"CheckIcon","size":"22"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }