import ClaimV2Service from "../services/claims-v2.service";
let dataFilters = [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Advisor",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name",
    cols: 12,
    tabla: "advisor_claims",
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Reason",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name",
    cols: 6,
    tabla: "reason_claims",
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Source",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name",
    cols: 6,
    tabla: "source_claims",
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Type",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name",
    cols: 6,
    tabla: "type_claims",
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Priority",
    model: null,
    options: [
      { id: "HIGH", label: "HIGH" },
      { id: "HALF", label: "HALF" },
      { id: "LOW", label: "LOW" },
    ],
    reduce: "id",
    selectText: "label",
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Departament",
    model: null,
    options: [
      { id: 14, text: "Bookeeping" },
      { id: 7, text: "Boost Credit" },
      { id: 3, text: "Business" },
      { id: 10, text: "Court Info" },
      { id: 27, text: "Creative" },
      { id: 6, text: "Credit Experts" },
      { id: 22, text: "Customer Service Digital" },
      { id: 5, text: "Debt Solution" },
      { id: 15, text: "Metamedia" },
      { id: 12, text: "Paragon" },
      { id: 11, text: "Specialist" },
      { id: 8, text: "Tax Research" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 12,
    visible: false,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From (Date Entry to the Level)",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To (Date Entry to the Level)",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
];

dataFilters.forEach(async (item) => {
  if (item.tabla) {
    const { types_claims, reason_claims, source_claims } =
      await ClaimV2Service.getAll();
    switch (item.tabla) {
      case "type_claims": {
        item.options = types_claims;
        break;
      }
      case "source_claims": {
        item.options = source_claims;
        break;
      }
      case "reason_claims": {
        item.options = reason_claims;
        break;
      }
    }
  }
});

export default dataFilters;
