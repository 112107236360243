export default [
    {
        key: "inquiry",
        label: "Inquiry",
        class: "text-left",
        visible: true,
    },

    // {
    //     key: "files",
    //     label: "Files",
    //     class: "text-center",
    //     visible: true,
    // },
    {
        key: "created_at",
        label: "Created by",
        class: "text-center",
        visible: true,
    },
    {
        key: "updated_at",
        label: "Answered by",
        class: "text-center",
        visible: true,
    },
    {
        key: "solution",
        label: "Solution",
        class: "text-center",
        visible: true,
    },
    {
        key: "views",
        label: "Views",
        class: "text-center",
        visible: true,
    },
]