<template>
  <b-modal
    v-model="showModal"
    title="Send Inquiry"
    :no-close-on-backdrop="true"
    @hidden="closeMe"
    centered
  >
    <b-row>
      <b-col md="12">
        <ValidationObserver
          ref="formObservableSpecialist"
          lazy
          v-bind:vid="'form-specialist'"
        >
          <b-form
            @submit.prevent="SendInquiry"
            ref="form-specialist"
            id="formularioInquiry"
          >
            <b-row>
              <b-col md="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="descriptionSolution"
                  rules="required"
                >
                  <b-form-group label="Description">
                    <b-form-textarea
                      id="textarea"
                      v-model="formSendSpecialist.inquiry"
                      placeholder="Enter description..."
                      rows="3"
                      max-rows="6"
                      :state="errors[0] ? false : null"
                      autofocus
                    ></b-form-textarea>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-button variant="secondary" @click="closeMe">Cancel</b-button>
      <b-button
        type="submit"
        variant="primary"
        :disabled="loadingSendInquiry"
        form="formularioInquiry"
      >
        <template v-if="loadingSendInquiry">
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </template>
        Send
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import ClaimV2Service from "@/views/commons/components/claims_v2/services/claims-v2.service.js";
import { mapGetters } from "vuex";
export default {
  name: "ModalInquiry",
  props: {
    selectedItemClaim: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      formSendSpecialist: {
        inquiry: "",
        claimId: 0,
        send: false,
        user_id: 0,
      },
      showModal: false,
      loadingSendInquiry: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  methods: {
    async SendInquiry() {
      try {
        const confirm = await this.showConfirmSwal();
        if (!confirm.value) {
          return;
        }

        this.loadingSendInquiry = true;
        await this.$refs.formObservableSpecialist.validate();
        if (this.formSendSpecialist.inquiry === "")
          throw new Error("description required");
        const { data } = await ClaimV2Service.saveInquiryClaim(
          this.formSendSpecialist
        );
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          data.message
        );
        this.loadingSendInquiry = false;
        this.closeMe();
        this.$emit("refresh");
      } catch (ex) {
        console.log(ex)
        this.showToast("danger", "top-right", "Faileds!", "XIcon", ex.message);
        this.loadingSendInquiry = false;
      }
    },
    closeMe() {
      this.showModal = false;
      this.$emit("close");
    },
  },
  mounted() {
    this.formSendSpecialist.user_id = this.currentUser.user_id;
    this.formSendSpecialist.claimId = this.selectedItemClaim.claim_id;
    this.formSendSpecialist.client_account = this.selectedItemClaim.account_id;
    this.showModal = true;
  },
};
</script>