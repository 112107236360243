<template>
  <b-row>
    <b-col md="8">
      <ValidationProvider
        v-slot="{ errors }"
        name="solutionDetails"
        rules="required"
      >
        <b-form-group
          id="solution-1"
          label="Solutions Offered"
          label-for="select-1"
          label-class="text-uppercase text-primary fw-700"
        >
          <v-select
            v-model="formSolution.solutions"
            multiple
            label="name"
            :options="dataSolutionsClaims"
            :reduce="(e) => e.id"
            id="select-1"
            :class="errors[0] ? 'border-danger' : null"
          ></v-select>
        </b-form-group>
      </ValidationProvider>
    </b-col>
    <b-col>
      <ValidationProvider
        v-slot="{ errors }"
        name="optionsSolutions"
        rules="required"
      >
        <b-form-group label="Select solution">
          <b-form-radio-group
            id="radio-group-1"
            v-model="formSolution.statusSolution"
            :options="optionsSolutions"
            name="radio-options"
            :class="solutionClass"
            :state="errors[0] ? false : null"
            class="custom-radio-group-claims-before"
          >
            <b-form-invalid-feedback :state="errors[0] ? false : null"
              >select option</b-form-invalid-feedback
            >
          </b-form-radio-group>
        </b-form-group>
      </ValidationProvider>
    </b-col>
    <b-col md="12">
      <ValidationProvider
        v-slot="{ errors }"
        name="descriptionSolution"
        rules="required"
      >
        <b-form-group
          id="description"
          label="Description"
          label-for="description-1"
        >
          <b-form-textarea
            id="description-1"
            v-model="formSolution.description"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
            no-resize
            :state="errors[0] ? false : null"
          ></b-form-textarea>
        </b-form-group>
      </ValidationProvider>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "LevelZero",
  props: {
    formSolution: {
      type: Object,
      required: true,
    },
    dataSolutionsClaims: {
      type: Array,
      required: true,
      default: () => [],
    },
    optionsSolutions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    solutionClass() {
      if (
        this.formSolution.statusSolution === "SOLVED" ||
        this.formSolution.statusSolution === "NOT-SOLVED"
      ) {
        return "custom-radio-group-claims";
      } else {
        return "";
      }
    },
  },
};
</script>
<style >
.custom-radio-group-claims
  .custom-control-input:checked[value="SOLVED"]
  ~ .custom-control-label::before {
  border-color: #28a745;
  background-color: #28a745;
}

.custom-radio-group-claims
  .custom-control-input:checked[value="NOT-SOLVED"]
  ~ .custom-control-label::before {
  border-color: #dc3545;
  background-color: #dc3545;
}

.custom-radio-group-claims-before
  .custom-control-label[for="radio-group-1_BV_option_0"] {
  color: #28a745;
  font-weight: bold;
}

.custom-radio-group-claims-before
  .custom-control-label[for="radio-group-1_BV_option_1"] {
  color: #dc3545;
  font-weight: bold;
}
</style>