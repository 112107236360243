<template>
  <b-modal
    size="xmd"
    ref="modalEditClaim"
    title="EDIT CLAIM "
    :no-close-on-backdrop="true"
    :ok-disabled="loading"
    :cancel-disabled="loading"
    :hide-header-close="loading"
    @hidden="closeMe"
    centered
  >
    <b-overlay
      id="overlay-background"
      :show="loading"
      :variant="skin"
      :opacity="0.5"
      blur="2px"
      rounded="sm"
    >
      <b-row>
        <b-col cols="12" md="4">
          <span class="title-tag">CLIENT NAME</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              <status-account :account="claim" :text="false"></status-account>
              {{ claim.client }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="4">
          <span class="title-tag">ACCOUNT</span>
          <div class="border border-primary rounded">
            <div
              class="text-primary d-flex align-items-center justify-content-center my-1s"
            >
              {{ claim.account }}
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="4">
          <span class="title-tag">PROGRAM</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
            >
              {{ claim.program }}
            </p>
          </div>
        </b-col>
      </b-row>
      <hr />
      <ValidationObserver ref="formObservable" lazy v-bind:vid="'form-cliente'">
        <b-row class="mt-1">
          <b-col cols="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="advisorSelect"
              rules="required"
            >
              <b-form-group
                id="advisor-select"
                label="Attended by"
                label-for="advisor-select"
              >
                <v-select
                  :options="dataUsersAdvisor"
                  label="name"
                  v-model="formClaim.userAttended"
                  :reduce="(x) => x.id"
                  :searchable="true"
                  placeholder="Select Source"
                  :class="errors[0] ? 'border-danger' : null"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="sourceOfClaim"
              rules="required"
            >
              <b-form-group
                id="options-1"
                label="Source of claim"
                label-for="select-1"
              >
                <v-select
                  :options="dataSourceClaims"
                  label="name"
                  v-model="formClaim.sourceOfClaim"
                  :reduce="(x) => x.id"
                  :searchable="false"
                  placeholder="Select Source"
                  :class="errors[0] ? 'border-danger' : null"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col :cols="formClaim.typeClaim === 3 ? 2 : 6">
            <ValidationProvider
              v-slot="{ errors }"
              name="typeClaim"
              rules="required"
            >
              <b-form-group
                id="options-2"
                label="Type claim"
                label-for="select-2"
              >
                <v-select
                  :options="dataTypeClaims"
                  label="name"
                  v-model="formClaim.typeClaim"
                  :reduce="(x) => x.id"
                  :searchable="false"
                  placeholder="Select Type"
                  :class="errors[0] ? 'border-danger' : null"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <template v-if="formClaim.typeClaim === 3">
            <b-col cols="2">
              <ValidationProvider
                v-slot="{ errors }"
                name="reasonForTheClaims"
                rules="required"
              >
                <b-form-group
                  id="amount-client"
                  label="amount client request"
                  label-for="amount-client"
                >
                  <money
                    v-bind="vMoney"
                    v-model="formClaim.amountClientRequest"
                    class="form-control"
                    :class="errors[0] ? 'is-invalid' : null"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="2">
              <ValidationProvider
                v-slot="{ errors }"
                name="reasonForTheClaims"
                rules="required"
              >
                <b-form-group
                  id="amount-departament"
                  label="amount department suggest"
                  label-for="amount-departament"
                >
                  <money
                    v-bind="vMoney"
                    v-model="formClaim.amountDepartmentSuggest"
                    class="form-control"
                    :class="errors[0] ? 'is-invalid' : null"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </template>

          <b-col cols="6">
            <ValidationProvider
              v-slot="{ errors }"
              name="reasonForTheClaims"
              rules="required"
            >
              <b-form-group
                id="options-3"
                label="Reason for the claims"
                label-for="select-3"
              >
                <v-select
                  :options="dataReasonClaims"
                  v-model="formClaim.reasonForTheClaims"
                  :reduce="(reason) => reason.id"
                  label="name"
                  multiple
                  placeholder="Select Reason"
                  :class="errors[0] ? 'border-danger' : null"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="6">
            <ValidationProvider
              v-slot="{ errors }"
              name="priority"
              rules="required"
            >
              <b-form-group
                id="options-4"
                label="Priority level"
                label-for="select-4"
              >
                <v-select
                  :options="dataPriority"
                  v-model="formClaim.priority"
                  :searchable="false"
                  placeholder="Select Priority"
                  :class="errors[0] ? 'border-danger' : null"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-group
                id="input-textarea"
                label="Claim description"
                label-for="textarea-description"
              >
                <b-form-textarea
                  v-model="formClaim.description"
                  id="textarea-no-resize"
                  placeholder="enter claim description"
                  rows="3"
                  no-resize
                  :state="errors[0] ? false : null"
                ></b-form-textarea>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-overlay>
    <template #modal-footer>
      <b-button variant="secondary" @click="closeMe">Cancel</b-button>
      <b-button
        :disabled="loading"
        variant="primary"
        id="save-claim"
        @click="editClaim"
        >Save</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClaimV2Service from "@/views/commons/components/claims_v2/services/claims-v2.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import VMoney from "v-money";
export default {
  components: {
    VMoney,
    StatusAccount,
  },
  props: {
    claim: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      formClaim: {
        sourceOfClaim: null,
        typeClaim: null,
        priority: null,
        reasonForTheClaims: [],
        accountId: null,
        claimId: null,
        description: null,
        userId: null,
        userAttended: null,
        amountClientRequest: 0,
        amountDepartmentSuggest: 0,
      },
      dataUsersAdvisor: [],
      dataSourceClaims: [],
      dataTypeClaims: [],
      dataReasonClaims: [],
      dataPriority: ["HIGH", "HALF", "LOW"],
      clientData: {
        id: "",
        client_id: "",
        advisor: "",
        program: "",
        client: "",
        email: "",
        account: "",
        chief: "",
        status: 0,
        module_id: 0,
        program_id: 0,
        selected: 0,
      },
      showModal: false,
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    "formClaim.typeClaim": {
      handler(value) {
        if (value == 3) {
          this.formClaim.amountClientRequest = this.claim.amount_client;
          this.formClaim.amountDepartmentSuggest = this.claim.amount_department;
        } else {
          this.$nextTick(() => {
            this.formClaim.amountClientRequest = null;
            this.formClaim.amountDepartmentSuggest = null;
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    //
  },
  async created() {
    this.addPreloader();
    await this.getDataOptions();
    await this.getInfoClaim();
    await this.getUsersAdvisor();
    this.removePreloader();
    this.toggleModal("modalEditClaim");
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "ClaimsV2Store/CHANGE_REFRESH_TABLE",
    }),
    closeMe() {
      this.$emit("close");
    },
    editedMe() {
      this.$emit("edited");
    },
    async editClaim() {
      try {
        let valid = await this.$refs.formObservable.validate();
        if (!valid) {
          throw new Error("Fields required");
        }

        const confirm = await this.showConfirmSwal(
          "Are you sure you want to edit this claim?"
        );
        if (!confirm.value) {
          return;
        }

        this.loading = true;

        const { data } = await ClaimV2Service.edit(this.formClaim);
        const { success, message } = data;
        if (!success) throw new Error(message);
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          message
        );
        this.editedMe();
      } catch (ex) {
        this.showToast("danger", "top-right", "Failed!", "XIcon", ex.message);
      } finally {
        this.loading = false;
      }
    },
    async getDataOptions() {
      try {
        const { data, message, success } =
          await ClaimV2Service.getTableRelatedToClaim();

        if (!success) throw new Error(message);
        const { source_claims, reason_claims, types_claims } = data;
        this.dataReasonClaims = reason_claims;
        this.dataSourceClaims = source_claims;
        this.dataTypeClaims = types_claims;
      } catch (ex) {
        this.showToast("danger", "top-right", "Failed!", "XIcon", ex.message);
      }
    },

    async getInfoClaim() {
      this.formClaim.userId = this.currentUser.user_id;
      this.formClaim.claimId = this.claim.claim_id;
      this.formClaim.sourceOfClaim = this.claim.source_claim_id;
      this.formClaim.typeClaim = this.claim.type_claim_id;
      this.formClaim.priority = this.claim.priority;
      this.formClaim.description = this.claim.description;
      this.formClaim.accountId = this.claim.account_id;
      this.formClaim.userAttended = this.claim.attended_by;
      this.formClaim.amountClientRequest = this.claim.amount_client;
      this.formClaim.amountDepartmentSuggest = this.claim.amount_department;
      let reasons = JSON.parse(this.claim.reasons);
      this.formClaim.reasonForTheClaims = reasons.map((reason) => reason.id);
    },
    async getUsersAdvisor() {
      try {
        const { data } = await ClaimV2Service.getUsersAdvisorForModule(
          this.moduleId
        );
        this.dataUsersAdvisor = data;
      } catch (ex) {
        console.log(ex.message);
      }
    },
  },
};
</script>
