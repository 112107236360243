export default [
    {
        key: "question",
        label: "Question",
        class: "text-left",
        visible: true,
    },
    {
        key: "answer",
        label: "Answer",
        class: "text-left",
        visible: true,
    },
    {
        key: "weight",
        label: "Weight",
        class: "text-center",
        visible: true,
    },
    {
        key: "icon",
        label: "Icon",
        class: "text-center",
        visible: true,
    },
];