<template>
  <div class="border-3 rounded-bottom">
    <filter-slot
      :filter="filterSlot.filters"
      :filterSearch="filterSlot.filterSearch"
      :paginate="filterSlot.paginate"
      :totalRows="filterSlot.totalRows"
      :startRow="filterSlot.startRow"
      :endRow="filterSlot.endRow"
      :toPage="filterSlot.toPage"
      @reload="$refs['updatesChargeListTable'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <!-- Table -->
      <b-table
        slot="table"
        no-provider-filtering
        ref="updatesChargeListTable"
        :items="myProvider"
        :fields="visibleFields"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        :busy.sync="isBusy"
        :per-page="filterSlot.paginate.perPage"
        :current-page="filterSlot.paginate.currentPage"
        id="table-pending-done-claim"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(client)="data">
          <span class="d-flex align-items-center" style="gap: 5px">
            <template>
              <router-link
                target="_blank"
                :to="{
                  name: dashboardRouteName,
                  params: {
                    idClient: `${data.item.account_id}`,
                  },
                }"
              >
                {{ data.item.client }}
              </router-link>
            </template>
            <b-badge v-if="data.item.on_hold" variant="light-warning"
              >HOLD</b-badge
            >

            <b-badge v-if="data.item.has_refund" variant="light-danger"
              >REFUND</b-badge
            >
          </span>
          <status-account :account="data.item" :text="true" />
          <div>
            <small class="d-block">
              {{ data.item.account }}
            </small>
            <small v-if="data.item.mobile" class="d-block">
              {{ data.item.mobile }}
            </small>
          </div>
        </template>
        <template #cell(reasons)="{ item }">
          <div
            id="badge-reasons"
            class="d-flex flex-row flex-wrap justify-content-center m-0"
            style="gap: 2px"
          >
            <b-badge
              id="badge-reason"
              v-for="(reason, index) in reasonData(item)"
              :key="index"
              class="px-1"
              variant="light-info"
            >
              {{ reason.name }}
            </b-badge>
          </div>
        </template>
        <template #cell(status_quality)="{ value, item }">
          <b-dropdown
            size="sm"
            dropright
            split
            :split-variant="listQualityStatusOutline[value]"
            :variant="listQualityStatusNoLight[value]"
            :disabled="disableDropDown"
          >
            <template #button-content>
              {{ value }}
            </template>
            <b-dropdown-item
              v-if="'UNDERVIEW' != value"
              @click="toggleStatusQuality('UNDERVIEW', item)"
            >
              <b-badge :variant="listQualityStatus['UNDERVIEW']">
                UNDERVIEW
              </b-badge>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="'ON INVESTIGATION' != value"
              @click="toggleStatusQuality('ON INVESTIGATION', item)"
            >
              <b-badge :variant="listQualityStatus['ON INVESTIGATION']">
                ON INVESTIGATION
              </b-badge>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="'NO ANSWER' != value"
              @click="toggleStatusQuality('NO ANSWER', item)"
            >
              <b-badge :variant="listQualityStatus['NO ANSWER']">
                NO ANSWER
              </b-badge>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(inquiries)="{ item }">
          <div class="d-block mb-1">
            Pending:
            <b-badge
              variant="light-warning"
              style="font-size: 12px; cursor: pointer"
              @click="openInquiriesByClaim(item, 1)"
            >
              {{ item.pending_inquiries }}
            </b-badge>
          </div>
          <div class="d-block">
            Solveds:
            <b-badge
              variant="light-success"
              style="font-size: 12px; cursor: pointer"
              @click="openInquiriesByClaim(item, 2)"
            >
              {{ item.answered_inquiries }}
            </b-badge>
          </div>
        </template>

        <template #cell(contact)="data">
          <div class="content_contact">
            <div class="box_contact">
              <div class="call_box">Call:</div>
              <div class="box_data">
                <div v-if="data.item.mobile != null">
                  <b-badge
                    class="cursor-pointer btn btn-sm btn-pd m-0"
                    variant="light-primary"
                    @click="openModalViewTracking(data.item, 'CALL')"
                    >{{ data.item.counter_call }}</b-badge
                  >
                  <b-button
                    v-if="validContact"
                    variant="success"
                    pill
                    class="btn btn_send"
                    @click="callRegister(data.item, 1)"
                  >
                    <feather-icon
                      v-if="validContact"
                      icon="PhoneIcon"
                      size="13"
                      class="align-middle cursor-pointer"
                    />
                  </b-button>
                </div>
                <div v-else class="text-danger">No mobile</div>
              </div>
            </div>
            <div class="box_contact">
              <div class="call_box">SMS:</div>
              <div class="box_data">
                <div v-if="data.item.mobile != null">
                  <b-badge
                    variant="light-primary"
                    class="cursor-pointer btn btn-sm btn-pd m-0"
                    @click="openModalViewTracking(data.item, 'SMS')"
                    >{{ data.item.counter_sms }}</b-badge
                  >

                  <b-button
                    v-if="validContact"
                    variant="primary"
                    pill
                    class="btn btn_send"
                    @click="sendSms(data.item)"
                  >
                    <feather-icon
                      v-if="validContact"
                      icon="MessageCircleIcon"
                      size="13"
                      class="align-middle cursor-pointer"
                    />
                  </b-button>
                </div>
                <div v-else class="text-danger">No mobile</div>
              </div>
            </div>
            <div class="box_contact border_none">
              <div class="call_box">Email:</div>
              <div class="box_data">
                <div v-if="data.item.email != null">
                  <b-badge
                    variant="light-primary"
                    class="cursor-pointer btn btn-sm btn-pd m-0"
                    @click="openModalViewTracking(data.item, 'EMAIL')"
                    >{{ data.item.counter_email }}</b-badge
                  >
                  <b-button
                    v-if="validContact"
                    variant="danger"
                    pill
                    class="btn btn_send"
                    @click="openModalSendEmail(data.item)"
                  >
                    <feather-icon
                      v-if="validContact"
                      icon="MailIcon"
                      size="13"
                      class="align-middle"
                    />
                  </b-button>
                </div>
                <div v-else class="text-danger">No email</div>
              </div>
            </div>
          </div>
        </template>

        <template #cell(type_claim)="{ item }">
          <small
            class="d-block"
            v-if="item.type_claim === 'REFUND' && item.level_id != 4"
          >
            <strong>Type: </strong>
            <b class="text-danger">
              {{ item.type_claim }}
            </b>
          </small>
          <small class="d-block" v-else>
            <strong>Type: </strong> {{ item.type_claim }}
          </small>
          <small class="d-block">
            <strong>Source: </strong>{{ item.source_claim }}
          </small>
          <small
            class="d-block"
            v-if="item.status === 'ANNULLED' || item.status === 'COMPLETED'"
          >
            <strong>Level: </strong>{{ item.level_id - 1 }}
          </small>
          <small class="d-block" v-if="item.amount_refund">
            <strong>Amount: $ {{ item.amount_refund }}</strong>
          </small>
        </template>
        <template #cell(created_at)="{ item }">
          <span class="d-block">{{ item.creator_of_the_registry }}</span>
          <span class="d-block">{{ item.created_at | myGlobalDay }} </span>
        </template>
        <template #cell(date_last_level)="{ item }">
          <span class="d-block">{{ item.scaled_user }}</span>
          <span class="d-block">{{ item.date_last_level | myGlobalDay }} </span>
        </template>

        <template #cell(priority)="{ item }">
          <b-badge :variant="variantPriority(item.priority)">
            {{ item.priority }}
          </b-badge>
        </template>
        <template #cell(advisor)="{ value }">
          {{ value || "None" }}
        </template>
        <template #cell(survey)="{ item }">
          <div class="">
            <div v-if="item.status == 'COMPLETED'">
              <b-button
                v-if="!item.exist_survey"
                size="sm"
                no-caret
                variant="link"
                class="p-0"
                @click="openSendSurveyModal(item)"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                  class="align-middle cursor-pointer"
                />
                Assign survey
              </b-button>
              <b-button
                v-if="item.exist_survey && item.survey_status"
                size="sm"
                no-caret
                variant="link"
                class="p-0"
                @click="surveyModal(item)"
              >
                <feather-icon
                  icon="ClipboardIcon"
                  size="17"
                  class="align-middle cursor-pointer mr-1"
                />
                View result
              </b-button>
            </div>
            <div v-if="showSendSmsButton(item)">
              <b-button
                size="sm"
                no-caret
                variant="link"
                class="p-0 text-primary d-flex"
                @click="sendSmsSurvey(item)"
              >
                <span class="content_btn">
                  <feather-icon
                    icon="MessageCircleIcon"
                    size="16"
                    class="align-middle mr-1"
                  />
                  Send SMS
                </span>
                <span class="send_badge">
                  <feather-icon
                    icon
                    :badge="'' + item.counter_sms_survey"
                    badge-classes="badge-primary pt_5"
                  />
                </span>
              </b-button>
            </div>
            <div v-else-if="showNoMobileMessage(item)" class="text-danger">
              <feather-icon
                icon="PhoneOffIcon"
                size="16"
                class="align-middle mr-1"
              />
              No mobile
            </div>

            <div v-if="showSendEmailButton(item)">
              <b-button
                size="sm"
                no-caret
                variant="link"
                class="p-0 text-success d-flex"
                @click="sendSurveyEmail(item)"
              >
                <span class="content_btn">
                  <feather-icon
                    icon="MailIcon"
                    size="16"
                    v-b-tooltip.hover.top="`Send Survey`"
                    class="cursor-pointer text-success mr-1"
                  />
                  Send Email
                </span>
                <span class="send_badge mt_5">
                  <feather-icon
                    icon
                    :badge="'' + item.counter_email_survey"
                    badge-classes="badge-danger pt_5"
                  />
                </span>
              </b-button>
            </div>
            <div v-else-if="showNoEmailMessage(item)" class="text-danger">
              <feather-icon
                icon="MailIcon"
                size="16"
                v-b-tooltip.hover.top="`Send Survey`"
                class="cursor-pointer text-danger mr-1"
              />
              No Email
            </div>
          </div>
        </template>
        <template #cell(sanction)="{ item }">
          <b-badge v-if="item.sanction != null" variant="light-warning">
            <feather-icon icon="AlertTriangleIcon" size="19" />
            {{ item.sanction }}
          </b-badge>
          <b-badge v-else variant="light-success"> NO SANCTION </b-badge>
        </template>
        <template #cell(action)="data">
          <div
            class="d-flex items-align-center justify-content-center"
            style="gap: 10px"
          >
            <feather-icon
              v-if="shouldShowButton"
              @click="openModalSolution(data.item)"
              icon="SendIcon"
              size="16"
              v-b-tooltip.hover.top="`Add Solution`"
              class="text-success cursor-pointer"
            />

            <feather-icon
              icon="EditIcon"
              size="16"
              v-if="isPendingLevelZero"
              @click="openModalEditClaim(data.item)"
              v-b-tooltip.hover.top="`Edit Claim`"
              class="text-warning cursor-pointer"
            />

            <feather-icon
              @click="openModalDetail(data.item)"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer text-primary"
              v-b-tooltip.hover.top="`View Detail`"
            />

            <feather-icon
              @click="annullScaledClaim(data.item)"
              v-if="shouldShowButton"
              icon="Trash2Icon"
              size="16"
              v-b-tooltip.hover.top="`Annull Claim`"
              class="text-danger cursor-pointer"
            />

            <feather-icon
              v-if="sendInquiryValid"
              @click="OpenModalInquiry(data.item)"
              icon="MessageSquareIcon"
              size="16"
              v-b-tooltip.hover.top="`Send Inquiry`"
              class="text-body cursor-pointer"
            />
          </div>
        </template>

        <template #cell(days_passed)="{ item }">
          <b-badge variant="light-primary">
            {{ item.days_passed }}
          </b-badge>
        </template>
        <template #cell(tracking)="data">
          <feather-icon
            icon="ListIcon"
            size="20"
            class="cursor-pointer text-primary"
            @click="trackingModal(data.item)"
          />
        </template>
      </b-table>
    </filter-slot>
    <ModalTracking
      v-if="showModalTracking"
      @close="closeTrackingModal"
      :claimId="claimId"
      :advisorName="advisor"
      :clientName="client"
    ></ModalTracking>
    <survey-modal
      v-if="showSurveyModal"
      :claimId="claimId"
      :clientName="clientName"
      @close="closeSurveyModal()"
    >
    </survey-modal>
    <ModalSolutionClaim
      :selectedClaim="selectedItemClaim"
      :show="showModalSolutionClaim"
      v-if="showModalSolutionClaim"
      @close="closeModalSolution"
    />
    <ModalDetail
      :selectedClaim="selectedItemClaim"
      :show="showModalDetail"
      v-if="showModalDetail"
      @close="closeModalDetail"
    />
    <TrackingContactModal
      v-if="showTrackingContactModal"
      :client="client"
      :claimId="claimId"
      :type="type"
      @close="closeTrackingCallModal"
    />
    <ModalInquiry
      v-if="ShowModalInquiry"
      :selectedItemClaim="selectedItemClaim"
      @refresh="refreshTable"
      @close="ShowModalInquiry = false"
    />
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="name_clients_arr[0]"
      :typesms="0"
      @closeModal="closeSendSms"
      @refresh="refreshTable"
    />
    <modal-send-email
      v-if="modalSendEmail"
      data
      :modalSendEmail="modalSendEmail"
      :nameLeads="name_clients_arr"
      :typesms="0"
      :isClaimSubject="true"
      @closeModal="closeSendEmail"
      @refresh="refreshTable"
    />
    <add-calls-and-sms-modal
      v-if="addCallsAndSmsModal"
      :client-data="clientData"
      :action-type="actionType"
      @closeModal="closeCallModal"
      @refresh="refreshTable"
    />
    <ModalAssignSurveyToClaim
      v-if="activeSendSurveyModal"
      :active="activeSendSurveyModal"
      :claim_id="claimSelected"
      @close="closeSendSurveyModal"
    />
    <modal-inquiries-details
      v-if="showInquiriesByClaim"
      :claimSelected="claimSelected"
      :status="inquiryStatus"
      :advisorName="advisor"
      :clientName="client"
      @close="closeInquiriesByClaim"
    />

    <ModalEditClaim
      v-if="showModalEditClaim"
      :claim="selectedItemClaim"
      @close="closeModalEditClaim"
      @edited="
        refreshTable();
        closeModalEditClaim();
      "
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import ClaimServiceLevel from "@/views/commons/components/claims_v2/services/claims-v2-level.service";
import DataFilters from "@/views/commons/components/claims_v2/data/dataFilters.data.js";
import FilterSlot from "@/views/specialist-digital/views/updates/slot/FilterSlot.vue";
import dataFields from "@/views/commons/components/claims_v2/data/Level/fields.pendingdone.data.js";

import ClaimService_v2 from "@/views/commons/components/claims_v2/services/claims-v2.service.js";
import ModalSolutionClaim from "@/views/commons/components/claims_v2/components/SolutionClaims/ModalSolutionClaim.vue";
import ModalDetail from "@/views/commons/components/claims_v2/components/Modals/ModalDetail.vue";

import SurveyModal from "../../components/Levels/modals/SurveyResults.vue";
import ModalTracking from "../../components/Levels/modals/TrackingClaim.vue";
import TrackingContactModal from "../../components/Levels/modals/TrackingCallModal.vue";
import ModalInquiry from "../../components/Levels/modals/ModalInquiry.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import AddCallsAndSmsModal from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/AddCallsAndSmsModal";
import ModalAssignSurveyToClaim from "@/views/commons/components/claims_v2/components/Modals/ModalAssignSurveyToClaim.vue";
import ModalInquiriesDetails from "@/views/commons/components/claims_v2/components/Modals/ModalInquiriesDetails.vue";
import ModalEditClaim from "@/views/commons/components/claims_v2/components/Modals/ModalEditClaim.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    vSelect,
    FilterSlot,
    "survey-modal": SurveyModal,
    ModalSolutionClaim,
    ModalTracking,
    ModalDetail,
    TrackingContactModal,
    ModalInquiry,
    ModalSendSms,
    ModalSendEmail,
    AddCallsAndSmsModal,
    ModalAssignSurveyToClaim,
    ModalInquiriesDetails,
    ModalEditClaim,
    StatusAccount,
  },

  data() {
    return {
      fields: dataFields,
      filterSlot: {
        filters: DataFilters,
        filterSearch: {
          type: "input",
          inputType: "text",
          placeholder: "Client...",
          model: "",
        },
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        //rows
        totalRows: 100,
        startRow: 0,
        endRow: 0,
        toPage: 0,
      },
      isBusy: false,
      parametros: {
        tab: "",
        module_id: 0,
        nSearch: "",
        nStartDate: null,
        nEnDate: null,
        nAdvisorId: null,
        nReasonClaimId: null,
        nSourceClaimId: null,
        nTypeCalimId: null,
        nPriority: null,
      },
      dataClaims: [],
      showModalTracking: false,
      claimId: null,
      showModalSolutionClaim: false,
      selectedItemClaim: null,
      showSurveyModal: false,
      clientName: "",
      advisor: "",
      client: "",
      showModalDetail: false,
      actionsOptions: ["dsdsf", "dsds"],
      showTrackingContactModal: false,
      ShowModalInquiry: false,
      modalSendSms: false,
      modalSendEmail: false,
      addCallsAndSmsModal: false,
      clientData: {},
      actionType: null,
      activeSendSurveyModal: false,
      claimSelected: null,
      showInquiriesByClaim: false,
      inquiryStatus: null,
      showModalEditClaim: false,
      listQualityStatus: {
        UNDERVIEW: "light-warning",
        "ON INVESTIGATION": "light-primary",
        "NO ANSWER": "light-info",
      },
      listQualityStatusOutline: {
        UNDERVIEW: "outline-warning",
        "ON INVESTIGATION": "outline-primary",
        "NO ANSWER": "outline-info",
      },
      listQualityStatusNoLight: {
        UNDERVIEW: "warning",
        "ON INVESTIGATION": "primary",
        "NO ANSWER": "info",
      },
      routers: {
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        12: "paragon-dashboard",
        11: "specialists-client-dashboard",
        14: "bookeeping-dashboard",
        18: "quality-client-dashboard",
        22: "ce-customer-service-dashboard",
      },
    };
  },
  watch: {
    getRefreshTable(value) {
      if (value) {
        this.$refs.updatesChargeListTable.refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },

  async created() {
    const { data } = await ClaimService_v2.getAdvisorModules();
    this.filterSlot.filters.forEach((item) => {
      if (item.tabla && item.tabla === "advisor_claims") {
        item.options = data;
      }
    });
    this.filterSlot.filters[7].visible = [16, 18].includes(this.moduleId);
    this.start();
  },
  computed: {
    isPendingLevelZero() {
      const modulesNoEdit = [18, 16];
      return (
        ["PENDING", "SCALED"].includes(this.stateClaim) &&
        this.stateLevel === 1 &&
        !modulesNoEdit.includes(this.moduleId)
      );
    },
    shouldShowButton() {
      const isPendingOrScaled = ["PENDING", "SCALED"].includes(this.stateClaim);
      let role_id = this.currentUser.role_id;

      let valid = false;
      switch (this.stateLevel) {
        case 1:
          valid =
            [1, 2, 17].includes(role_id) && ![18, 16].includes(this.moduleId);
          break;
        case 2:
          valid = this.moduleId == 18 && [1, 2, 3].includes(role_id);
          break;
        case 3:
          valid = this.moduleId == 18 && [1, 2, 3, 17].includes(role_id);
          break;
        case 4:
          valid =
            (this.moduleId == 16 && [2, 17].includes(role_id)) || role_id == 1;
          break;
      }
      return isPendingOrScaled && valid;
    },
    sendInquiryValid() {
      const validRoles = [1, 2, 3, 17];
      const validStateLevels = [2, 3];
      const validStates = ["COMPLETED", "ANNULLED"];
      const validModuleId = 18;
      return (
        validRoles.includes(this.currentUser.role_id) &&
        validStateLevels.includes(this.stateLevel) &&
        !validStates.includes(this.stateClaim) &&
        this.moduleId == validModuleId
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    dashboardRouteName() {
      const routers = {
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        14: "bookeeping-dashboard",
        18: "quality-client-dashboard",
        16: "management-client-dashboard",
      };
      return routers[this.moduleId] || null;
    },
    stateLevel() {
      return this.$route.matched[2].meta.stateLevel;
    },
    stateClaim() {
      return this.$route.matched[2].meta.stateClaim;
    },
    visibleFields() {
      this.fields.find((f) => f.key == "status_quality").visible = [
        2, 3,
      ].includes(this.stateLevel);

      return this.fields.filter((field) => field.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      getRefreshTable: "ClaimsV2Store/getRefreshTable",
    }),
    validContact() {
      let valid = false;
      let role_id = this.currentUser.role_id;
      switch (this.stateLevel) {
        case 2:
          valid = [1, 2, 3].includes(role_id) && this.moduleId == 18;
          break;
        case 3:
          valid = [1, 2, 3].includes(role_id) && this.moduleId == 18;
          break;
      }
      return valid;
    },
    doneSubTab() {
      return this.$route.matched[2].meta.status == 2;
    },
    annulledSubTab() {
      return this.$route.matched[2].meta.status == 5;
    },
    modulClaim() {
      const { modul_id } = this.currentUser;
      return [16, 18].includes(modul_id) ? null : modul_id;
    },
    disableDropDown() {
      const { modul_id } = this.currentUser;

      return modul_id != 18;
    },
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "ClaimsV2Store/CHANGE_REFRESH_TABLE",
      A_PENDING_CLAIMS: "ClaimsStore/A_PENDING_CLAIMS",
    }),

    async myProvider(ctx) {
      try {
        let parametros = {
          tab: this.stateClaim,
          level_id: this.stateLevel,
          ...ctx,
          nSearch: this.filterSlot.filterSearch.model,
          nStartDate: this.filterSlot.filters[0].model,
          nEnDate: this.filterSlot.filters[1].model,
          nAdvisorId: this.filterSlot.filters[2].model,
          nReasonClaimId: this.filterSlot.filters[3].model,
          nSourceClaimId: this.filterSlot.filters[4].model,
          nTypeCalimId: this.filterSlot.filters[5].model,
          nPriority: this.filterSlot.filters[6].model,
          nDepartment: this.filterSlot.filters[7].model,
          nStartEntryDate: this.filterSlot.filters[8].model,
          nEndEntryDate: this.filterSlot.filters[9].model,
          nModuleId: this.modulClaim,
        };
        let data = await ClaimServiceLevel.getClaimData(parametros);
        this.startPage = data.from;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        this.filterSlot.totalRows = data.total;
        this.filterSlot.toPage = data.to;
        this.filterSlot.startRow = data.from;
        this.filterSlot.endRow = data.to;
        this.dataClaims = data.data;
      } catch (ex) {
        this.dataClaims = [];
      }
      return this.dataClaims;
    },
    reasonData(item) {
      return JSON.parse(item.reasons);
    },

    variantPriority(value) {
      let colorPriority = "secondary";
      switch (value) {
        case "HIGH": {
          colorPriority = "light-danger";
          break;
        }
        case "HALF": {
          colorPriority = "light-warning";
          break;
        }
        case "LOW": {
          colorPriority = "light-success";
          break;
        }
      }
      return colorPriority;
    },
    optionsStatusQuality(value) {
      return this.listQualityStatus.filter((item) => item.value != value);
    },
    async sendSmsSurvey(item) {
      const confirm = await this.showConfirmSwal();
      if (confirm.value) {
        this.addPreloader();
        try {
          const params = {
            leads: item.lead_id,
            modul: this.currentUser.modul_id,
            message: `Hola ${item.client}! Soy Maria Cortez, representante del departamento de Quality.\n\nQueremos asegurarnos de que tu experiencia con nuestro servicio haya sido satisfactoria. Por favor, tómate un momento para completar nuestra encuesta de satisfacción en el siguiente enlace: ${item.url_survey}.\n\nAgradecemos tu tiempo y tus comentarios, ya que nos ayudarán a mejorar continuamente nuestros servicios. Si tienes alguna pregunta adicional o necesitas asistencia adicional, no dudes en contactarnos.\n\n¡Muchas gracias por elegir AMG y por tu participación!\n\nAtentamente,\nMaria Cortez\nQuality Control\nAMG`,
            user: this.currentUser.user_id,
            hasImage: false,
            type: "SMS_SURVEY",
            claimId: item.claim_id,
          };
          await ClaimService_v2.sendSms(params);
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "SMS Sent Successfully"
          );
          this.refreshTable();
        } catch (e) {
          console.log(e);
        } finally {
          this.removePreloader();
        }
      }
    },
    resetAllFilters() {
      this.filterSlot.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterSlot.filterSearch.model = null;
      this.$refs.updatesChargeListTable.refresh();
    },

    closeModalSolution() {
      this.showModalSolutionClaim = false;
      this.selectedItemClaim = null;
    },
    openModalSolution(item) {
      this.selectedItemClaim = item;
      this.showModalSolutionClaim = true;
    },
    openSendSurveyModal(item) {
      this.sendSurveyData = item;
      this.selectedItemClaim = item;
      this.claimSelected = item.claim_id;

      this.activeSendSurveyModal = true;
    },
    closeSendSurveyModal(refresh) {
      if (refresh) this.$refs.updatesChargeListTable.refresh();

      this.claimSelected = null;

      this.activeSendSurveyModal = false;
    },
    surveyModal(claimData) {
      if (claimData.survey_status != "PENDING") {
        this.claimId = claimData.claim_id;
        this.clientName = claimData.client;
        this.showSurveyModal = true;
      }
    },
    closeSurveyModal() {
      this.showSurveyModal = false;
    },
    trackingModal(data) {
      this.claimId = data.claim_id;
      this.advisor = data.advisor;
      this.client = data.client;
      this.showModalTracking = true;
    },
    closeTrackingModal() {
      this.showModalTracking = false;
    },
    closeTrackingCallModal() {
      this.showTrackingContactModal = false;
    },
    openModalDetail(item) {
      this.selectedItemClaim = item;
      this.showModalDetail = true;
    },
    closeModalDetail() {
      this.showModalDetail = false;
      this.selectedItemClaim = null;
    },
    closeSendSms() {
      this.modalSendSms = false;
    },
    closeSendEmail() {
      this.modalSendEmail = false;
    },
    closeCallModal() {
      this.addCallsAndSmsModal = false;
    },
    async sendSms(data) {
      this.name_clients_arr = [
        {
          name: data.client,
          id: data.account_id,
          account: data.account,
          claimId: data.claim_id,
        },
      ];
      this.modalSendSms = true;
    },
    openModalSendEmail(data) {
      this.name_clients_arr = [
        {
          name: data.client,
          id: data.account_id,
          account: data.account,
          claimId: data.claim_id,
          moduleId: data.module_id,
        },
      ];
      this.modalSendEmail = true;
    },
    //** */
    async sendContactEmail(item) {
      const confirm = await this.showConfirmSwal();
      if (confirm.value) {
        this.addPreloader();
        try {
          const parameters = {
            id_account: item.account_id,
            modul: item.module_id,
            subject: "Claim notification",
            user: this.currentUser.user_id,
            message: "Hi, we are in the process of resolving your claim.",
            type: "EMAIL",
            subtype: 1,
            claimId: item.claim_id,
          };
          await ClaimServiceLevel.sendContactEmail(parameters);
          this.refreshTable();
        } catch (error) {
          console.log(error);
        }
        this.removePreloader();
      }
    },
    async sendSurveyEmail(item) {
      const confirm = await this.showConfirmSwal();
      if (confirm.value) {
        this.addPreloader();
        try {
          let params = {
            id_account: item.account_id,
            modul: item.module_id,
            subject: "AMG survey",
            user: this.currentUser.user_id,
            message: "your claim has been solved by our team",
            type: "EMAIL_SURVEY",
            subtype: 2,
            claimId: item.claim_id,
          };
          await ClaimService_v2.sendSurvey(params);
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Message Sent Successfully"
          );
          this.refreshTable();
        } catch (e) {
          console.log(e);
        } finally {
          this.removePreloader();
        }
      }
    },
    async callRegister(item, type) {
      this.clientData = item;
      this.actionType = type;
      if (this.clientData.mobile !== null) {
        this.addCallsAndSmsModal = true;
      } else {
        this.showToast(
          "warning",
          "top-right",
          "Warning!",
          "AlertTriangleIcon",
          "This client don't have a mobile registered."
        );
      }
    },
    openModalViewTracking(item, type) {
      this.type = type;
      this.client = item.client;
      this.claimId = item.claim_id;
      this.showTrackingContactModal = true;
    },
    refreshTable() {
      this.$refs.updatesChargeListTable.refresh();
    },
    OpenModalInquiry(item) {
      this.ShowModalInquiry = true;
      this.selectedItemClaim = item;
    },
    async annullScaledClaim(item) {
      const annullmentReason = await this.$swal({
        title: "Claim annullment reason",
        input: "textarea",
        showCancelBUtton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",

        inputValidator: (observation) => {
          return !observation ? "Please enter your reason" : undefined;
        },
      });

      if (annullmentReason.value) {
        const confirm = await this.showConfirmSwal(
          "Are you sure you want to annull this claim?"
        );
        if (confirm.value) {
          this.addPreloader();
          try {
            let params = {
              claim_id: item.claim_id,
              reason: annullmentReason.value,
              level_id: this.stateLevel,
              user_id: this.currentUser.user_id,
              client_account_id: item.account_id,
              module_id: item.module_id,
            };
            await ClaimService_v2.annullClaim(params);
            this.showSuccessSwal();
            this.CHANGE_REFRESH_TABLE(true);
            await this.A_PENDING_CLAIMS(this.moduleId);
          } catch (e) {
            console.log(e);
          } finally {
            this.removePreloader();
          }
        }
      }
    },
    filterColumn(key, tab) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = tab;
    },
    openModalEditClaim(item) {
      this.selectedItemClaim = item;
      this.showModalEditClaim = true;
    },
    closeModalEditClaim() {
      this.showModalEditClaim = false;
    },
    start() {
      this.filterColumn("days_passed", this.doneSubTab);
      this.filterColumn(
        "survey",
        this.doneSubTab && [16, 18].includes(this.moduleId)
      );
      this.filterColumn("contact", [2, 3].includes(this.stateLevel));
      this.filterColumn("inquiries", [2, 3, 4].includes(this.stateLevel));
      this.filterColumn("sanction", this.doneSubTab);
    },
    openInquiriesByClaim(item, value) {
      this.claimSelected = item.claim_id;
      this.inquiryStatus = value;
      this.advisor = item.advisor;
      this.client = item.client;
      this.showInquiriesByClaim = true;
    },

    closeInquiriesByClaim(value) {
      this.inquiryStatus = value;
      this.showInquiriesByClaim = false;
    },
    showSendSmsButton(item) {
      return item.exist_survey && item.mobile && !item.survey_status;
    },
    showNoMobileMessage(item) {
      return !item.mobile && item.exist_survey && !item.survey_status;
    },
    showSendEmailButton(item) {
      return item.exist_survey && item.email && !item.survey_status;
    },
    showNoEmailMessage(item) {
      return !item.email && item.exist_survey && !item.survey_status;
    },
    async toggleStatusQuality(newValue, item) {
      const { claim_id } = item;

      item.status_quality = newValue;

      await ClaimService_v2.changeStatusQuality({
        claim_id,
        status_quality: newValue,
      });
    },
  },
};
</script>

<style scoped>
.content_contact {
  margin: 0 auto;
  width: 108px;
}
.box_contact {
  width: 100%;
  display: flex;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}
.call_box {
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}
.box_data {
  padding: 3px 0;
  width: 100%;
  text-align: left;
}
.border_none {
  border-bottom: none;
}
.btn_send {
  padding: 4px;
  margin-left: 5px;
  opacity: 0.6;
}
.send_badge {
  display: block;
  width: 24px;
  margin: 7px 0 5px 0;
  height: 8px;
}
.content_btn {
  width: 95px;
}
.mt_5 {
  margin-top: 12px;
}
.pt_5 {
  padding-top: 6px;
}
</style>
