var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":_vm.option1Selected ? 8 : 6}},[_c('ValidationProvider',{attrs:{"name":"selectSolutions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"solution-1","label":"SOLUTIONS OFFERED","label-for":"select-1","label-class":"text-uppercase text-primary fw-700"}},[_c('v-select',{class:errors[0] ? 'border-danger' : null,attrs:{"multiple":"","label":"name","options":_vm.dataSolutionsClaims,"reduce":function (e) { return e.id; },"id":"select-solutions"},model:{value:(_vm.formSolution.solutions),callback:function ($$v) {_vm.$set(_vm.formSolution, "solutions", $$v)},expression:"formSolution.solutions"}})],1)]}}])})],1),(!_vm.option1Selected)?_c('b-col',{attrs:{"md":!_vm.option1Selected ? '3' : null}},[_c('ValidationProvider',{attrs:{"name":"optionsSolutions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Result"}},[_c('b-form-radio-group',{staticClass:"custom-radio-group-claims-before",class:_vm.solutionClass,attrs:{"id":"result-radio","options":_vm.optionsSolutions,"name":"result-radio","state":errors[0] ? false : null},model:{value:(_vm.formSolution.statusSolution),callback:function ($$v) {_vm.$set(_vm.formSolution, "statusSolution", $$v)},expression:"formSolution.statusSolution"}},[_c('b-form-invalid-feedback',{attrs:{"state":errors[0] ? false : null}},[_vm._v("select an option")])],1)],1)]}}],null,false,1792986346)})],1):_vm._e(),(!_vm.option1Selected && _vm.showOptionsLevels)?_c('b-col',{attrs:{"md":!_vm.option1Selected && _vm.showOptionsLevels ? '3' : null}},[_c('ValidationProvider',{attrs:{"name":"optionsSolutionsLevels","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Send to"}},[_c('b-form-radio-group',{attrs:{"id":"send-to-level","options":_vm.LevelsData,"name":"send-to-level","state":errors[0] ? false : null},model:{value:(_vm.levelSelected),callback:function ($$v) {_vm.levelSelected=$$v},expression:"levelSelected"}},[_c('b-form-invalid-feedback',{attrs:{"state":errors[0] ? false : null}},[_vm._v("select an level")])],1)],1)]}}],null,false,3877690091)})],1):_vm._e(),(_vm.option1Selected)?_c('b-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"name":"optionRefound","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Is there a refund?","label-class":"text-uppercase"}},[_c('b-form-radio-group',{attrs:{"id":"selected-refound","options":_vm.optionRefound,"name":"selected-refound","state":errors[0] ? false : null},model:{value:(_vm.refundSelected),callback:function ($$v) {_vm.refundSelected=$$v},expression:"refundSelected"}},[_c('b-form-invalid-feedback',{attrs:{"state":errors[0] ? false : null}},[_vm._v("select an option")])],1)],1)]}}],null,false,2839537671)})],1):_vm._e(),(_vm.refundSelected)?_c('b-col',{attrs:{"cools":"4"}},[_c('ValidationProvider',{attrs:{"name":"amountRefund","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Refund amount","label-class":"text-uppercase"}},[_c('money',_vm._b({staticClass:"form-control",class:errors[0] ? 'is-invalid' : null,model:{value:(_vm.formSolution.amountRefund),callback:function ($$v) {_vm.$set(_vm.formSolution, "amountRefund", $$v)},expression:"formSolution.amountRefund"}},'money',_vm.vMoney,false))],1)]}}],null,false,1758407945)})],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"descriptionSolution","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"description","label":"Description","label-for":"description-1"}},[_c('b-form-textarea',{attrs:{"id":"description-1","placeholder":"Enter something...","rows":"3","max-rows":"6","no-resize":"","state":errors[0] ? false : null},model:{value:(_vm.formSolution.description),callback:function ($$v) {_vm.$set(_vm.formSolution, "description", $$v)},expression:"formSolution.description"}})],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }