<template>
  <b-modal
    v-model="showModal"
    size="xmd"
    hide-footer
    title="Claim Tracking"
    @hide="closeModal"
  >
    <div>
      <b-row>
        <b-col cols="12" md="6">
          <span class="title-tag">CLIENT NAME</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ clientName }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <span class="title-tag">Advisor</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ advisorName || "None" }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="table-responsive">
      <b-table
        ref="trackingTable"
        :items="myProvider"
        :fields="Fields"
        primary-key="id"
        responsive="sm"
        show-empty
        sticky-header="50vh"
        class="blue-scrollbar mt-2"
        :busy.sync="isBusy"
        no-provider-filtering
      >
        <template #cell(date_tk)="data">
          <div>
            {{ data.item.date_tk | myGlobalWithHour }}
          </div>
        </template>
        <template #cell(status)="data">
          <div>
            <b-badge :variant="badgeColor(data.item.status)">{{
              data.item.status
            }}</b-badge>
          </div>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>
<script>
import ClaimService from "@/views/commons/components/claims_v2/services/claims-v2.service.js"
import Fields from "@/views/commons/components/claims_v2/data/fields.tracking.js"
export default {
  data() {
    return {
      showModal: false,
      isBusy: false,
      Fields,
    }
  },
  props: {
    claimId: Number,
    advisorName: String,
    clientName: String,
  },
  created() {
    this.showModal = true
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    async myProvider() {
      const formData = {
        claimId: this.claimId,
      }
      const data = await ClaimService.getTrackingclaim(formData)
      return data
    },
    badgeColor(badge) {
      let badgeClass = {
        PENDING: "light-warning",
        SCALED: "light-primary",
        REACTIVATED: "light-info",
        COMPLETED: "light-success",
        ANNULLED: "light-danger",
        EDITED: "light-secondary",
      }
      return badgeClass[badge]
    },
  },
}
</script>