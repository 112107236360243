export default [
  {
    key: "client",
    label: "Client",
    class: "text-left",
    visible: true,
  },
  {
    key: "type_claim",
    label: "Details",
    class: "text-left",
    visible: true,
  },
  {
    key: "status_quality",
    label: "Status Quality",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "reasons",
    label: "Reasons",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "advisor",
    label: "Advisor",
    class: "text-center",
    visible: true,
  },
  {
    key: "inquiries",
    label: "Inquiries",
    class: "text-left",
    visible: true,
  },
  {
    key: "contact",
    label: "Contact",
    class: "text-center",
    visible: true,
  },
  {
    key: "priority",
    label: "Priority",
    class: "text-center",
    visible: true,
  },
  {
    key: "created_at",
    label: "Created By",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "date_last_level",
    label: "Date Entry",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "days_passed",
    label: "Days",
    class: "text-center",
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    class: "text-center",
    visible: true,
  },
  {
    key: "survey",
    label: "Survey",
    class: "text-left",
    visible: true,
  },
  {
    key: "sanction",
    label: "Sanction",
    class: "text-left",
    visible: true,
  },
  {
    key: "action",
    label: "Actions",
    class: "text-center",
    visible: true,
  },
];
